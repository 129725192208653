export type TFieldManual = IFieldManualItem[];

export interface IFieldManualItem {
  title: string;
  description: string;
  textArr: any[];
}

export const fieldManual = [
  {
    title: "Getting Started",
    description: "Logging into the field application.",
    textArr: ["Navigate to https://workforcedailyreport.com in your browser where you will see the login page.", "Enter your email address and password and click enter.", "You will be taken to the main menu page where you will see a list of menu icons.", "Click on SI.", "On the next page click the Select A Job Name button which will show you a list of jobs assigned to you as foreman.", "Click the appropriate job.", "You will be navigated to the Sign In/Sign Out page."],
  },
  {
    title: "Sign In/Sign Out (SI)",
    description: "Operating the Sign In/Sign Out page.",
    textArr: [
      "Start by selecting a shift in the Shift drop-down menu at the top right of the screen.",
      "Next you will see the Work Type drop-down enabled.",
      "Select the work type from the WORK TYPE drop-down.",
      "Work Zones are generally pre-set by admin, but foreman are allowed to update, add, or remove work zones.  All updates to work zones can only be done with admin approval.",
      "You will see a list of employees assigned to your worksite with ",
      ["Cost Code drop-down", "Name", "Last 4 SSN", "Time In button", "Time Out button", "Notes button"],
      "Signing in employees requires the employee to click/tap the Time In button on the same row as their information.",
      "The IN modal will appear with the employee’s name, the sign in date and time and a signature box.",
      "The employee must using their finger or mouse to sign in the signature box and click the Confirm button",
    ],
  },
  {
    title: "Work Report (WR)",
    description: "Operating the Work Report page.",
    textArr: [
      "To get to the Work Report page click/tap the menu icon at the top left of the screen and click/tap the WR icon.",
      "At the top of the screen you will see the same information as in the Sign In/Sign Out page.",
      "Each section of the Work Report page can be expanded by clicking/taping on the Arrow Down toggle to the left of the section name.",
      "All data entered into any of the work report fields automatically saves.",
      "Title of Change Order - is an optional field for Change Orders, T&M, or Extra Work Performed.",
      "Employees section allows you to add any Double Time for the employee or Notes if any should be needed for any reason.  It also allows you to monitor employee hours",
      "Employee hours default to 7, but if an employee needs to sign out early, the Hours section will reflect the change after the employee has signed out.  Reasons for early signing out must be entered in the notes section for the employee",
      "Description - is the description of the Work Report.  It is encouraged to add as much description as needed.",
      "Material Description/Deliveries - is where you enter material desc riptions and deliveries.  In the Work Zone field enter a work zone from the dropdown which you can see pre-populated in the Work Zones section at the top of the screen.  In Images field you will be able to: ",
      ["Add images", "View images", "Add attachments - PDF files"],
      "To add a new row to Material Description/Deliveries click the plus (+) icon at the top-right of the section",
      "To remove items in Material Description/Deliveries, click the minus (-) icon to the right of each row",
      "Incident Report - If there is no incident, this should default to No",
      "Tasks - are designed to track tasks throughout the project.  Each row consists of: ",
      ["Name - the name of the task", "Status - whether the task is Starting, In Progress, or Completed", "Percentage Complete - manually enter a number", "Description - an accurate description of the task"],
      "To add a new row to Tasks, click the plus (+) icon at the top-right of the section",
      "To remove Tasks, click the minus (-) icon to the right of each row",
      "Remarks - Add any remarks concerning the project",
      "Approvals - Add signatures where appropriate.",
      "After you have submitted the report and you feel you have submitted it in error, you will be able to Recall the Work Report.  Refer to the Reports (R) section for details.",
    ],
  },
];
