import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getUser } from "../../api/auth";
import Header from "../../common/header/Header";
import Modal from "../../common/modal/Modal";
import { formattedDate, setFormattedTime } from "../../helpers/dateTime";
import WorkersList from "../workersList/WorkersList";

import { createInitData, createJobData, createShift, focusType, getFocus, getShiftData, setFocus, upateEmployeeTimeOut, updateAgencyRepSignature, updateContractorRepSignature, updateEmployeeRegularTime, updateEmployeeTimeIn, updateNotesSignature, updatePM, updatePMSignature, updateTimeInSignature, updateTimeOutSignature } from "../../api/si";

import "./SigninSignout.scss";

const SigninSignout: React.FC = () => {
  const [worker, setWorker] = useState<any | null>(null);
  const [jobId, setJobId] = useState<string | null>(null);
  const [showJobsList, setShowJobsList] = useState<boolean>(false);
  const [showJobSearch, setShowJobSearch] = useState<boolean>(true);
  const [showSI, setShowSI] = useState<boolean>(false);
  const [showTimeModal, setShowTimeModal] = useState<boolean | string | null>(false);
  const [showNotesModal, setShowNotesModal] = useState<boolean | null>(false);
  const [showSigModal, setShowSigModal] = useState<boolean | null>(false);
  const [notesModalData, setNotesModalData] = useState<any | null>(null);
  const [modalData, setModalData] = useState<any | null>(null);
  const [notes, setNotes] = useState<string | null>(null);
  const [signatures, setSignatures] = useState<any | null>(null);
  const [timesheetData, setTimesheetData] = useState<any | null>(new Map());
  const [worksiteData, setWorksiteData] = useState<any | null>(null);
  const [shift, setShift] = useState<string>("");
  const [siteFocus, setSiteFocus] = useState<focusType | null | undefined>(null);
  const [shiftData, setShiftData] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    getFocus()
      .then(data => {
        console.log("DATA: ", data);
        if (!data) {
          navigate("/jobs-list");
        }
        setShift(data?.shift);
        setSiteFocus(data);
        getShiftData(data)
          .then(data => {
            setShiftData(data);
            setShowJobSearch(false);
            setShowSI(true);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
  }, [getShiftData, setShiftData]);
  console.log("SHIFT: ", shift);
  const renderJobSearch = () => {
    return (
      <div className="si-jobsearch">
        <label className="si-jobsearch-label">Job Name</label>
        <button
          className="si-jobsearch-btn"
          onClick={() => {
            setShowJobsList(true);
            setShowJobSearch(false);
          }}
        >
          Select A Job Name
        </button>
      </div>
    );
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>, id: string) => {
    setNotes(e.target.value);
    const employees = shiftData.siteEmployees.employees.map((employee: { id: string }) => {
      if (employee.id === id) {
        const updatedEmployee = {
          ...employee,
          notes: e.target.value,
        };
        return updatedEmployee;
      }
      return employee;
    });
    const updatedShift = {
      ...shiftData,
      employees,
    };
    setShiftData(updatedShift);
  };

  const renderJobsList = () => {
    if (worker.jobs.length > 0) {
      return (
        <ul className="si-jobslist">
          {worker.jobs
            .filter((_job: any) => _job?.job !== null)
            .map((job: { job: { id: string; jobName: string } }) => {
              return (
                <li
                  key={job?.job?.id}
                  className="si-jobslist-item"
                  onClick={async () => {
                    setJobId(job.job?.id);
                    setWorksiteData(job.job);
                    createInitData(job.job);
                    setShowJobsList(false);
                    setShowSI(true);
                  }}
                >
                  <p className="si-jobslist-item-name">{job?.job?.jobName}</p>
                </li>
              );
            })}
        </ul>
      );
    } else {
      return <div>You Have No Jobs</div>;
    }
  };

  const handleCreateJobData = async (shift: string, data: any, jobName: string) => {
    const newShiftData = await createJobData(data, shift, jobName);
    setShiftData(newShiftData);
  };

  const handleSetShift = async () => {
    console.log("SETTING SHIFT...");
    const _shiftData = await createShift(await getFocus());
    setShiftData(_shiftData);
  };

  const renderTimesheet = () => {
    const _jobId = jobId;
    if (shiftData) {
      const site = shiftData;
      return (
        <div className="si-timesheet">
          <Header
            site={shiftData || site}
            workShift={shift => {
              setShift(shift);
              setFocus({ jobName: site.jobName, shift });
              handleSetShift();
              // handleCreateJobData(shift, site, site.jobName);
              getFocus().then(data => setSiteFocus(data));
            }}
          />
          <WorkersList
            shift={shift}
            employees={site?.siteEmployees?.employees}
            setTimeIn={(firstName: string, lastName: string, id: string, type: string, renderTimeModal: boolean) => {
              setModalData({
                firstName,
                lastName,
                id,
                type,
              });
              setShowTimeModal(renderTimeModal);
            }}
            setTimeOut={(firstName: string, lastName: string, id: string, type: string, renderTimeModal: boolean) => {
              setModalData({
                firstName,
                lastName,
                id,
                type,
              });
              setShowTimeModal(renderTimeModal);
            }}
            timesheetData={(id: string) => getTimesheetData(id)}
            notesModal={(firstName: string, lastName: string, id: string, renderNotesModal: boolean, notesValue) => {
              setNotesModalData({
                firstName,
                lastName,
                id,
                notesValue,
              });
              setShowNotesModal(renderNotesModal);
            }}
          />
          {/* <Button1 btnClassName="si-timesheet-signaturebtn" btnType="button" btnName="signatures" btnValue="Signatures" onclick={() => {
            console.log('Signatures Clicked...');
            setModalData({
              projectManager: shiftData.foreman
            })
            setShowSigModal(true);
          }} /> */}
        </div>
      );
    }
  };

  const getTimesheetData = (id: string) => {
    return timesheetData.get(id);
  };

  const calculateRegHours = (timein: Date, timeout: Date) => {
    const t1 = new Date(timein).getTime();
    const t2 = new Date(timeout).getTime();
    let diff = (t1 - t2) / 1000;
    let timeDiff = diff / (60 * 60);
    // console.log('CALCULATED HOURS: ', Math.abs(timeDiff), 'TIME 1: ', t1, 'TIME 2: ', t2);
    // return Math.abs(Math.round(diff));
    return Math.abs(timeDiff);
  };

  const handleTimeIn = async () => {
    let newShiftData = await updateEmployeeTimeIn(siteFocus!, modalData?.id, new Date(`${formattedDate()}T${setFormattedTime()}:00`));

    newShiftData = await updateEmployeeRegularTime(siteFocus!, modalData?.id, 7);
    setShiftData(newShiftData);
  };

  const handleTimeOut = async () => {
    let newShiftData = await upateEmployeeTimeOut(siteFocus!, modalData?.id, new Date(`${formattedDate()}T${setFormattedTime()}:00`));
    setShift(newShiftData);
    const employee = shiftData?.siteEmployees.employees?.find((el: { _id: string }) => el?._id === modalData?.id);
    // const calculatedHours = calculateRegHours(employee?.timeIn, employee?.timeOut);
    const calculatedHours = calculateRegHours(employee?.timeIn, new Date(`${formattedDate()}T${setFormattedTime()}:00`));

    if (calculatedHours < 7 || isNaN(calculatedHours) === true) {
      newShiftData = await updateEmployeeRegularTime(siteFocus!, employee._id, isNaN(calculatedHours) ? 0 : calculatedHours);
      /**
       * REM0VE BELOW newShiftData
       */
      // newShiftData = await updateEmployeeRegularTime(siteFocus!, employee.id, isNaN(calculatedHours) ? 7 : 7);
      setShiftData(newShiftData);
    } else {
      setShiftData(newShiftData);
    }
  };

  const handleTimeInSignature = async (data: string) => {
    await updateTimeInSignature(siteFocus!, modalData.id, data);
  };

  const handleTimeOutSignature = async (data: string) => {
    await updateTimeOutSignature(siteFocus!, modalData.id, data);
  };

  const renderTimeModal = () => {
    return (
      <Modal
        modalType="time"
        timeModalData={modalData}
        onSaveTime={() => {
          console.log("SAVING TIME IN: ", modalData);
          setShowTimeModal(false);
          if (modalData.type === "timeIn") {
            handleTimeIn();
          } else if (modalData.type === "timeOut") {
            handleTimeOut();
          }
        }}
        getSigData={async data => {
          if (modalData.type === "timeIn") {
            handleTimeInSignature(data!);
            await getShiftData(siteFocus!);
          } else if (modalData.type === "timeOut") {
            handleTimeOutSignature(data!);
            await getShiftData(siteFocus!);
          }
        }}
        onCancelTime={() => {
          setShowTimeModal(false);
        }}
      />
    );
  };

  const handleNotesSignature = async (notes: string) => {
    const newShiftData = await updateNotesSignature(siteFocus!, notesModalData.id, notes);
    setShiftData(newShiftData);
  };

  const getNotesValue = (id: string) => {
    const employeeNote = shiftData?.siteEmployees.employees.find((e: { _id: string }) => e._id === id);
    if (employeeNote?.notes) {
      return employeeNote?.notes;
    }
    return;
  };

  const renderNotesModal = () => {
    return (
      <Modal
        modalType="notes"
        notesModalData={notesModalData}
        handleNotesChange={e => handleNotesChange(e, notesModalData?.id)}
        onSaveNotes={() => {
          setShowNotesModal(false);
          handleNotesSignature(notes!);
        }}
        notesValue={getNotesValue(notesModalData?.id)}
        onSaveTime={() => console.log("notes modal time")}
        onCancelNotes={() => {
          setShowNotesModal(false);
        }}
      />
    );
  };

  const renderSignaturesModal = () => {
    // console.log("Rendering Sig Modal...", worker);
    return (
      <Modal
        modalType="signatures"
        signaturesData={{
          id: worker?.id,
          projectManager: `${worker?.firstName} ${worker?.lastName}`,
          contractorRep: "N/A",
          agencyRep: "N/A",
          type: "signatures",
        }}
        onSavePMSignature={async data => {
          setShiftData({ ...shiftData, projectManagerSig: data });
          await updatePMSignature(siteFocus!, data);
          await updatePM(siteFocus!, `${worker?.firstName} ${worker?.lastName}`);
        }}
        onSaveContractorRepSig={async data => {
          setShiftData({ ...shiftData, contractorRepSig: data });
          await updateContractorRepSignature(siteFocus!, data);
        }}
        onSaveAgencyRepSig={async data => {
          console.log("ON SAVE AGENCY REP SIG", data);
          setShiftData({ ...shiftData, agencyRepSig: data });
          await updateAgencyRepSignature(siteFocus!, data);
        }}
        onSaveSignatures={async () => {
          const updatedShift = await getShiftData(siteFocus!);
          setShiftData(updatedShift);
          setShowSigModal(false);
        }}
        onCancelSignatures={() => setShowSigModal(false)}
      />
    );
  };

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }

  if (getUser() && getUser().role === "ADMIN") {
    return <Navigate to="/jobs" replace={true} />;
  }

  if (error) {
    return <div className="si-nodata">{error}</div>;
  }
  return (
    <div className="si">
      {/* {!shiftData && showJobSearch && <JobsList />} */}
      {/* {showJobSearch && renderJobSearch()} */}
      {/* {showJobsList && renderJobsList()} */}
      {showSI && renderTimesheet()}
      <>{showTimeModal && renderTimeModal()}</>
      <>{showNotesModal && renderNotesModal()}</>
      <>{showSigModal && renderSignaturesModal()}</>
    </div>
  );
};

export default SigninSignout;
