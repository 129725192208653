import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { setCurrentWeather } from "./api/weather";

import { getUser } from "./api/auth";
import NavBar from "./components/navBar/NavBar";
import HomeScreen from "./components/homeScreen/HomeScreen";
import SigninSignout from "./components/signinSignout/SigninSignout";
import WorkReport from "./components/workreport/WorkReport";
import Checklist from "./components/checklist/Checklist";
import RegisterUser from "./components/auth/registerUser/RegisterUser";
import UserLogin from "./components/auth/userLogin/UserLogin";
import Jobs from "./components/admin/jobs/Jobs";
import Agency from "./components/admin/agency/Agency";
import Employees from "./components/admin/employees/Employees";
import Worksites from "./components/admin/worksites/Worksites";
import WorkReports from "./components/admin/workreports/";
import Reports from "./components/reports/Reports";
import WorkReportToPrint from "./components/admin/workreports/WorkReportToPrint";
import ChecklistReports from "./components/admin/checklistReports";
import ChecklistReportToPrint from "./components/admin/checklistReports/ChecklistReportToPrint";
import ChecklistReportPdf from "./components/admin/checklistReports/ChecklistReportPdf";
import ChecklistReportDownload from "./components/admin/checklistReports/ChecklistReportDownload";
import XlUpload from "./components/admin/xlsxUpload/XlUpload";
import EmployeeRates from "./components/admin/employeeRates/EmployeeRates";
import LaborReports from "./components/admin/laborReports";
import JobCostingReportPrint from "./components/admin/jobCostingReport/JobCostingReportPrint";
import Camera from "./components/camera/Camera2";
import JobImages from "./components/admin/jobImages/JobImages";

import "./App.scss";
import PdfGenerator from "./components/admin/checklistReports/PdfGenerator";
import ConfirmUser from "./components/auth/confirmUser/ConfirmUser";
import EmployeeImageId from "./components/employeeImageId/EmployeeImageId";
import FacialVerification from "./components/facialVerification/FacialVerification";
import Budget from "./components/admin/budget/Budget";

import { pdfjs } from "react-pdf";
import PdfAttachments from "./components/admin/pdfAttachments/PdfAttachments";
import JobsList from "./components/jobSearch/JobsList";
import FieldManual from "./components/fieldManual/FieldManual";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const App: React.FC = () => {
  const matched = window.matchMedia("(prefers-color-scheme: dark)");
  console.log("DARK MODE? ", matched);

  const getPath = () => window.location.pathname;

  useEffect(() => {
    setCurrentWeather();
    getPath();
  }, [setCurrentWeather]);

  return (
    <>
      {getUser() && getPath() !== "/workreport-to-print" && getPath() !== "/checklistreport-download" && getPath() !== "/checklistreports-to-print" && getPath() !== "/job-costing-report-print" && <NavBar />}
      <main className="main">
        <Routes>
          <Route path="/user-login" element={<UserLogin />} />
          <Route path="/register-user" element={<RegisterUser />} />
          <Route path="/" element={<HomeScreen />} />
          <Route path="/si" element={<SigninSignout />} />
          <Route path="/wr" element={<WorkReport />} />
          <Route path="/ic" element={<Checklist />} />
          <Route path="/eid" element={<EmployeeImageId />} />
          <Route path="/facial-verification" element={<FacialVerification />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/agencies" element={<Agency />} />
          <Route path="/employees" element={<Employees />} />
          <Route path="/worksites" element={<Worksites />} />
          <Route path="/workreports" element={<WorkReports />} />
          <Route path="/submitted-reports" element={<Reports />} />
          <Route path="/workreport-to-print" element={<WorkReportToPrint />} />
          <Route path="/checklistreports" element={<ChecklistReports />} />
          <Route path="/checklistreports-to-print" element={<ChecklistReportToPrint />} />
          <Route path="/checklistreports-pdf" element={<ChecklistReportPdf />} />
          <Route path="/pdf-generator" element={<PdfGenerator />} />
          <Route path="/checklistreport-download" element={<ChecklistReportDownload />} />
          <Route path="/costcodes" element={<XlUpload />} />
          <Route path="/employee-job-rates" element={<EmployeeRates />} />
          <Route path="/labor-reports" element={<LaborReports />} />
          <Route path="/job-costing-report-print" element={<JobCostingReportPrint />} />
          <Route path="/job-cam" element={<Camera />} />
          <Route path="/job-images" element={<JobImages />} />
          <Route path="/user-confirmation" element={<ConfirmUser />} />
          <Route path="/budget" element={<Budget />} />
          <Route path="/pdf-attachments" element={<PdfAttachments />} />
          <Route path="/jobs-list" element={<JobsList />} />
          <Route path="/field-manual" element={<FieldManual />} />
        </Routes>
      </main>
    </>
  );
};

export default App;
