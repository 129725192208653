import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import { getChecklistReport } from "../../../graphql/queries";

import "./ChecklistReportDownload.scss";
import { formatTheDate } from "../../../helpers/dateTime";

const ChecklistReportDownload: React.FC = () => {
  const [clReport, setClReport] = useState<any | null>(null);

  let [searchParams] = useSearchParams();

  const fetchChecklistreport = async () => {
    const reportId: any = searchParams.get("checklistid");
    console.log("CHECKLIST REPORT ID: ", reportId);
    const clData = await getChecklistReport(reportId);

    if (clData) {
      setClReport(clData.data.checklist);
    }
  };

  useEffect(() => {
    fetchChecklistreport();
  }, []);

  const exportPDF = () => {
    const input = document.getElementById("clreport");
    html2canvas(input!, { logging: true, useCORS: true }).then(async canvas => {
      const imageWidth = 208;
      const imgHeight = (canvas.height * imageWidth) / canvas.width;
      const imgData = canvas.toDataURL("img/png");
      // const pdf = new jsPDF('p', 'mm', 'a4');
      const pdf = new jsPDF("p", "mm", [500.28, 241.89], true);
      pdf.addImage(imgData, "PNG", 0, 0, imageWidth, imgHeight);
      pdf.save(`${clReport.checklistType}.pdf`);
    });
  };
  console.log(clReport);

  return (
    <>
      {/* <button
        className="clreport-downloadbtn"
        onClick={() => {
          exportPDF();
        }}
      >
        Download {clReport?.checklistType} Report
      </button> */}
      <div className="clreport" id="clreport">
        <div className="clreport-titlegrp">
          <div className="clreport-titlegrp-address">
            <p className="clreport-titlegrp-address-text">BISSETTA & LIST, INC.</p>
            <p className="clreport-titlegrp-address-text">420 WEST 49th STREET</p>
            <p className="clreport-titlegrp-address-text">NEW YORK, NY 10019</p>
          </div>
          <div className="clreport-titlegrp-clname">
            <p>PLUMBING CHECKLIST</p>
          </div>
        </div>

        <div className="clreport-topcontainer">
          <h1 className="clreport-topcontainer-title">{clReport?.checklistType}</h1>
          <div className="clreport-topcontainer-row1">
            <div className="clreport-topcontainer-row1-contractno">
              <label className="clreport-topcontainer-row1-contractno-label" htmlFor="contractNo">
                Contract No:
              </label>
              <p className="clreport-topcontainer-row1-contractno-text">{clReport?.contractNo ? clReport?.contractNo : "N/A"}</p>
            </div>
            <div className="clreport-topcontainer-row1-description">
              <label className="clreport-topcontainer-row1-description-label" htmlFor="description">
                Description:
              </label>
              <p className="clreport-topcontainer-row1-description-text">{clReport?.description ? clReport?.description : "N/A"}</p>
            </div>
          </div>

          <div className="clreport-topcontainer-row2">
            <div className="clreport-topcontainer-row2-contractor">
              <label className="clreport-topcontainer-row2-contractor-label" htmlFor="contractor">
                Contractor:
              </label>
              <p className="clreport-topcontainer-row2-contractor-text">{clReport?.agency?.agencyName ? clReport?.agency?.agencyName : "N/A"}</p>
            </div>
            <div className="clreport-topcontainer-row2-sub_contractor">
              <label className="clreport-topcontainer-row2-sub_contractor-label" htmlFor="sub_contractor">
                Sub-Contractor:
              </label>
              <p className="clreport-topcontainer-row2-sub_contractor-text">{clReport?.sub_contractor ? clReport?.sub_contractor : "N/A"}</p>
            </div>
          </div>

          <div className="clreport-topcontainer-row3">
            <div className="clreport-topcontainer-row3-location">
              <label className="clreport-topcontainer-row3-location-label" htmlFor="location">
                Location:
              </label>
              <p className="clreport-topcontainer-row3-location-text">{clReport?.location ? clReport?.location : "N/A"}</p>
            </div>
            <div className="clreport-topcontainer-row3-date">
              <label className="clreport-topcontainer-row3-date-label" htmlFor="date">
                Date:
              </label>
              <p className="clreport-topcontainer-row3-date-text">{clReport?.date ? formatTheDate(clReport?.date) : "N/A"}</p>
            </div>
          </div>

          <div className="clreport-topcontainer-row4">
            <div className="clreport-topcontainer-row4-preparedBy">
              <label className="clreport-topcontainer-row4-preparedBy-label" htmlFor="preparedBy">
                Prepared By:
              </label>
              <p className="clreport-topcontainer-row4-preparedBy-text">{clReport?.preparedBy ? clReport?.preparedBy : "N/A"}</p>
            </div>
            <div className="clreport-topcontainer-row4-datePrepared">
              <label className="clreport-topcontainer-row4-datePrepared-label" htmlFor="datePrepared">
                Date Prepared:
              </label>
              <p className="clreport-topcontainer-row4-datePrepared-text">{clReport?.datePrepared ? formatTheDate(clReport?.datePrepared) : "N/A"}</p>
            </div>
          </div>

          <div className="clreport-topcontainer-row5">
            <div className="clreport-topcontainer-row5-inspectorname">
              <label className="clreport-topcontainer-row5-inspectorname-label" htmlFor="inspectorname">
                Inspector's Name:
              </label>
              <p className="clreport-topcontainer-row5-inspectorname-text">{clReport?.inspectorName ? clReport?.inspectorName : "N/A"}</p>
            </div>
          </div>

          <div className="clreport-topcontainer-row6">
            <div className="clreport-topcontainer-row6-surveillanceReport">
              <label className="clreport-topcontainer-row6-surveillanceReport-label" htmlFor="surveillanceReport">
                Surveillance Report:
              </label>
              <p className="clreport-topcontainer-row6-surveillanceReport-text">{clReport?.surveillanceReport ? clReport?.surveillanceReport : "N/A"}</p>
            </div>
            <div className="clreport-topcontainer-row6-drawing">
              <label className="clreport-topcontainer-row6-drawing-label" htmlFor="drawing">
                Drawing:
              </label>
              <p className="clreport-topcontainer-row6-drawing-text">{clReport?.drawing ? clReport?.drawing : "N/A"}</p>
            </div>
          </div>
        </div>

        <div className="clreport-fieldtasks">
          <h4 className="clreport-fieldtasks-title">FIELD TASKS</h4>
          <ul className="clreport-fieldtasks-list">
            {clReport?.fieldTasks?.map((task: { id: number; question: string; answer: string }, idx: number) => (
              <li className="clreport-fieldtasks-list-item" key={task.id}>
                <div className="clreport-fieldtasks-list-item-grp">
                  <span className="clreport-fieldtasks-list-item-grp-number">{idx + 1}.</span>
                  <p className="clreport-fieldtasks-list-item-grp-q">{task.question}</p>
                  <p className="clreport-fieldtasks-list-item-grp-ans">{task.answer}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* <div className="pagebreak" /> */}

        <div className="clreport-nonconformancesrmks">
          <label className="clreport-nonconformancesrmks-label" htmlFor="nonconformancesrmks">
            Nonconformances Remarks:
          </label>
          <p className="clreport-nonconformancesrmks-text">{clReport?.nonconformanceRemarks}</p>
        </div>

        <div className="clreport-additionalrmks">
          <label className="clreport-additionalrmks-label" htmlFor="additionalrmks">
            Additional Remarks:
          </label>
          <p className="clreport-additionalrmks-text">{clReport?.remarks}</p>
        </div>

        <div className="clreport-sigcontainer">
          <div className="clreport-sigcontainer-row1">
            <div className="clreport-sigcontainer-row1-name">
              <label className="clreport-sigcontainer-row1-name-label" htmlFor="name">
                Name:
              </label>
              <p className="clreport-sigcontainer-row1-name-text">{clReport?.additionalRemarks?.name1 ? clReport?.additionalRemarks.name1 : "N/A"}</p>
            </div>
            <div className="clreport-sigcontainer-row1-title">
              <label className="clreport-sigcontainer-row1-title-label" htmlFor="title">
                Title:
              </label>
              <p className="clreport-sigcontainer-row1-title-text">{clReport?.additionalRemarks?.title1 ? clReport?.additionalRemarks?.title1 : "N/A"}</p>
            </div>
          </div>

          <div className="clreport-sigcontainer-row2">
            <div className="clreport-sigcontainer-row2-signature">
              <label className="clreport-sigcontainer-row2-signature-label" htmlFor="signature">
                Signature:
              </label>
              <img className="clreport-sigcontainer-row2-signature-text" src={clReport?.additionalRemarks?.signature1 ? clReport?.additionalRemarks?.signature1 : "N/A"} />
            </div>
            <div className="clreport-sigcontainer-row2-date">
              <label className="clreport-sigcontainer-row2-date-label" htmlFor="date">
                Date:
              </label>
              <p className="clreport-sigcontainer-row2-date-text">{clReport?.additionalRemarks?.date1 ? formatTheDate(clReport?.date) : "N/A"}</p>
            </div>
          </div>

          <div className="clreport-sigcontainer-row1">
            <div className="clreport-sigcontainer-row1-name">
              <label className="clreport-sigcontainer-row1-name-label" htmlFor="name">
                Name:
              </label>
              <p className="clreport-sigcontainer-row1-name-text">{clReport?.additionalRemarks?.name2 ? clReport?.additionalRemarks.name2 : "N/A"}</p>
            </div>
            <div className="clreport-sigcontainer-row1-title">
              <label className="clreport-sigcontainer-row1-title-label" htmlFor="title">
                Title:
              </label>
              <p className="clreport-sigcontainer-row1-title-text">{clReport?.additionalRemarks?.title2 ? clReport?.additionalRemarks.title2 : "N/A"}</p>
            </div>
          </div>

          <div className="clreport-sigcontainer-row2">
            <div className="clreport-sigcontainer-row2-signature">
              <label className="clreport-sigcontainer-row2-signature-label" htmlFor="signature">
                Signature:
              </label>
              <img className="clreport-sigcontainer-row2-signature-text" src={clReport?.additionalRemarks?.signature2 ? clReport?.additionalRemarks?.signature2 : "N/A"} />
            </div>
            <div className="clreport-sigcontainer-row2-date">
              <label className="clreport-sigcontainer-row2-date-label" htmlFor="date">
                Date:
              </label>
              <p className="clreport-sigcontainer-row2-date-text">{clReport?.additionalRemarks?.date2 ? formatTheDate(clReport?.date) : "N/A"}</p>
            </div>
          </div>

          <div className="clreport-sigcontainer-row1">
            <div className="clreport-sigcontainer-row1-name">
              <label className="clreport-sigcontainer-row1-name-label" htmlFor="name">
                Name:
              </label>
              <p className="clreport-sigcontainer-row1-name-text">{clReport?.additionalRemarks?.name3 ? clReport?.additionalRemarks.name3 : "N/A"}</p>
            </div>
            <div className="clreport-sigcontainer-row1-title">
              <label className="clreport-sigcontainer-row1-title-label" htmlFor="title">
                Title:
              </label>
              <p className="clreport-sigcontainer-row1-title-text">{clReport?.additionalRemarks?.title3 ? clReport?.additionalRemarks.title3 : "N/A"}</p>
            </div>
          </div>

          <div className="clreport-sigcontainer-row2">
            <div className="clreport-sigcontainer-row2-signature">
              <label className="clreport-sigcontainer-row2-signature-label" htmlFor="signature">
                Signature:
              </label>
              <img className="clreport-sigcontainer-row2-signature-text" src={clReport?.additionalRemarks?.signature3 ? clReport?.additionalRemarks?.signature3 : "N/A"} />
            </div>
            <div className="clreport-sigcontainer-row2-date">
              <label className="clreport-sigcontainer-row2-date-label" htmlFor="date">
                Date:
              </label>
              <p className="clreport-sigcontainer-row2-date-text">{clReport?.additionalRemarks?.date3 ? formatTheDate(clReport?.date) : "N/A"}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChecklistReportDownload;
