import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { faMinusCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getWorksites, getJobs, getEmployees, updateWorksite, createWorksite, updateJob, deleteWorksite, getEmployeeJobRates } from "../../../graphql/queries";
import { getUser } from "../../../api/auth";

import AdminMenu from "../AdminMenu";

import "./Worksites.scss";

const Worksites: React.FC = () => {
  const [worksites, setWorksites] = useState<any | null>(null);
  const [showArchived, setShowArchived] = useState<string>("active");
  const [selectedSite, setSelectedSite] = useState<any | null>(null);
  const [createSiteData, setCreateSiteData] = useState<any | null>(null);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [canAddWorksite, setCanAddWorksite] = useState<boolean>(false);
  const [jobs, setJobs] = useState<any | null>(null);
  const [employees, setEmployees] = useState<any | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<{ _id: string; firstName: string; lastName: string; middleInitial: string } | null>(null);
  const [employeeRates, setEmployeeRates] = useState<any[]>();
  const [employeeHasRate, setEmployeeHasRate] = useState<boolean>();
  const [employeesToShow, setEmployeesToShow] = useState<{ _id: string; firstName: string; lastName: string; middleInitial: string }[]>([]);

  const [modifyWorksite, { data: modifyWorksiteData, loading: modifyWorksiteLoading, error: modifyWorksiteError }] = useMutation(updateWorksite);

  const [addWorksite, { data: addWorksiteData, loading: addWorksiteLoading, error: addWorksiteError }] = useMutation(createWorksite);

  const [removeWorksite] = useMutation(deleteWorksite);

  const [modifyJob, { data: modifyJobData, loading: modifyJobLoading, error: modifyJobError }] = useMutation(updateJob);

  const navigation = useNavigate();

  const fetchWorksites = async () => {
    const currentworksites = await getWorksites();
    if (currentworksites) {
      const worksiteCopy = { ...currentworksites };
      setWorksites(worksiteCopy);
    }
  };

  const fetchEmployees = async () => {
    const currentEmployees = await getEmployees();
    if (currentEmployees) {
      const employeesArr = [...currentEmployees.data.workers];
      const sortedEmployees = employeesArr.sort((a: any, b: any) => a.lastName - b.lastName);
      setEmployees(sortedEmployees);
    }
  };

  const fetchJobs = async () => {
    const jobsList = await getJobs();
    setJobs(jobsList.data.jobs);
  };

  const fetchEmployeeRates = async () => {
    const data = await getEmployeeJobRates();
    setEmployeeRates(data.employeeRates);
  };

  useEffect(() => {
    fetchWorksites();
    fetchEmployees();
    fetchJobs();
    fetchEmployeeRates();
  }, []);

  const handleCreateWorksite = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const employeeData = employeesToShow?.map((el: any) => {
      return el._id;
    });
    const newWorksite = await addWorksite({
      variables: {
        body: {
          foremanId: createSiteData?.foremanId,
          jobId: createSiteData?.jobId,
          employees: employeeData,
        },
      },
    });
    const worksiteId = newWorksite.data.createWorksiteEmployees.id;
    await modifyJob({ variables: { input: { worksiteId, id: newWorksite.data.createWorksiteEmployees.job.id } } });
    window.location.reload();
  };

  const handleModifyWorksite = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const employeeData = employeesToShow?.map((el: any) => {
      return el._id;
    });
    const modifyWorksiteData = {
      foremanId: createSiteData?.foremanId,
      jobId: createSiteData?.jobId,
      employees: employeeData,
      archive: createSiteData?.archive,
    };

    await modifyWorksite({ variables: { body: { id: createSiteData.id, ...modifyWorksiteData } } });
  };

  const handleDeleteWorksite = async (id: string) => {
    await removeWorksite({ variables: { id } });
    window.location.reload();
  };

  const renderArchivedWorksitelist = () => {
    return (
      <div className="worksites-wrapper-sitelist">
        <h4 className="worksites-wrapper-sitelist-title">Active Work Sites</h4>
        <ul className="worksites-wrapper-sitelist-list">
          {worksites?.worksiteEmployeesList
            ?.filter((s: any) => s.archive === true)
            .map((site: any) => {
              return (
                <li
                  className={`worksites-wrapper-sitelist-list-item ${site.id === selectedSite?.id ? "selecteditem" : ""}`}
                  key={site.id}
                  onClick={() => {
                    const siteCopy = { ...site };
                    setSelectedSite(siteCopy);
                    setCanEdit(false);
                  }}
                >
                  {site.job?.jobName}
                </li>
              );
            })}
        </ul>
      </div>
    );
  };

  const renderWorksitelist = () => {
    return (
      <div className="worksites-wrapper-sitelist">
        <h4 className="worksites-wrapper-sitelist-title">Active Work Sites</h4>
        <ul className="worksites-wrapper-sitelist-list">
          {worksites?.worksiteEmployeesList?.map((site: any) => {
            return (
              <li
                className={`worksites-wrapper-sitelist-list-item ${site.id === selectedSite?.id ? "selecteditem" : ""}`}
                key={site.id}
                onClick={() => {
                  const siteCopy = { ...site };
                  setSelectedSite(siteCopy);
                  setCanEdit(false);
                }}
              >
                {site.job?.jobName}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const renderNormalDetails = () => {
    return (
      <>
        <h4 className="worksites-wrapper-sitedetails-title">Worksite Details</h4>
        <button
          className={`worksites-wrapper-sitedetails-editbtn ${selectedSite !== null && "editbtnactive"}`}
          type="button"
          onClick={() => {
            if (selectedSite !== null && !canEdit) {
              setCanEdit(true);
              setCanAddWorksite(false);
              setCreateSiteData(selectedSite);
              setEmployeesToShow(selectedSite?.employees);
            }
          }}
        >
          Edit
        </button>
        <div className="worksites-wrapper-sitedetails-row1">
          <div className="worksites-wrapper-sitedetails-row1-agency">
            <label className="worksites-wrapper-sitedetails-row1-agency-label" htmlFor="agency">
              Agency
            </label>
            <p className="worksites-wrapper-sitedetails-row1-agency-text">{selectedSite?.job?.agency?.agencyName}</p>
          </div>
          <div className="worksites-wrapper-sitedetails-row1-name">
            <label className="worksites-wrapper-sitedetails-row1-name-label" htmlFor="name">
              Name
            </label>
            <p className="worksites-wrapper-sitedetails-row1-name-text">{selectedSite?.job?.jobName}</p>
          </div>
        </div>

        <div className="worksites-wrapper-sitedetails-row2">
          <div className="worksites-wrapper-sitedetails-row2-contractno">
            <label className="worksites-wrapper-sitedetails-row2-contractno-label" htmlFor="contractNo">
              Contract Number
            </label>
            <p className="worksites-wrapper-sitedetails-row2-contractno-text">{selectedSite?.job?.contractNo}</p>
          </div>

          <div className="worksites-wrapper-sitedetails-row2-location">
            <label className="worksites-wrapper-sitedetails-row2-location-label" htmlFor="location">
              Location
            </label>
            <p className="worksites-wrapper-sitedetails-row2-location-text">{selectedSite?.job?.location}</p>
          </div>
        </div>

        <div className="worksites-wrapper-sitedetails-row3">
          <div className="worksites-wrapper-sitedetails-row3-employees">
            <label className="worksites-wrapper-sitedetails-row3-employees-label" htmlFor="employees">
              Employees
            </label>
            <ul className="worksites-wrapper-sitedetails-row3-employees-list">
              {selectedSite?.employees?.map(
                (
                  employee: {
                    firstName: string;
                    lastName: string;
                    id: string;
                  },
                  idx: string
                ) => {
                  return (
                    <li key={idx} className="worksites-wrapper-sitedetails-row3-employees-list-item">
                      {employee?.firstName} {employee?.lastName}
                    </li>
                  );
                }
              )}
            </ul>
          </div>
          <div className="worksites-wrapper-sitedetails-row3-foreman">
            <label className="worksites-wrapper-sitedetails-row3-foreman-label" htmlFor="foreman">
              Foreman
            </label>
            <p className="worksites-wrapper-sitedetails-row3-foreman-text">
              {selectedSite?.foreman?.firstName} {selectedSite?.foreman?.lastName}
            </p>
          </div>
        </div>
      </>
    );
  };

  const renderWorksiteDetails = () => {
    return (
      <div className="worksites-wrapper-sitedetails">
        <>
          {!canEdit && !canAddWorksite && renderNormalDetails()}
          {canEdit && !canAddWorksite && renderUpdateWorksite()}
          {!canEdit && canAddWorksite && renderAddWorksite()}
        </>
      </div>
    );
  };

  const renderAddWorksite = () => {
    return (
      <form className="worksites-wrapper-sitedetails-addworksite">
        <h4 className="worksites-wrapper-sitedetails-title">Create New Worksite</h4>
        <div className="worksites-wrapper-sitedetails-addworksite-row1">
          <div className="worksites-wrapper-sitedetails-addworksite-row1-job">
            <label className="worksites-wrapper-sitedetails-addworksite-row1-job-label" htmlFor="job">
              Job
            </label>
            <select
              className="worksites-wrapper-sitedetails-addworksite-row1-job-list"
              name="jobName"
              id="jobName"
              onChange={e => {
                if (jobs) {
                  jobs?.map((job: { jobName: string; id: string }) => {
                    if (job.id === e.target.value) {
                      setCreateSiteData({
                        ...createSiteData,
                        job: job,
                        jobId: e.target.value,
                      });
                    }
                  });
                }
              }}
            >
              <option className="worksites-wrapper-sitedetails-addworksite-row1-job-list-option">Select A Job</option>
              {jobs?.map((job: { jobName: string; id: string }) => {
                return (
                  <option className="worksites-wrapper-sitedetails-addworksite-row1-job-list-option" key={job.id} value={job?.id}>
                    {job?.jobName}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="worksites-wrapper-sitedetails-addworksite-row1-foreman">
            <label className="worksites-wrapper-sitedetails-addworksite-row1-foreman-label" htmlFor="foreman">
              Foreman
            </label>
            <select
              className="worksites-wrapper-sitedetails-addworksite-row1-foreman-list"
              name="foremanId"
              id="foremanId"
              onChange={e => {
                setCreateSiteData({
                  ...createSiteData,
                  foremanId: e.target.value,
                });
              }}
            >
              <option className="worksites-wrapper-sitedetails-addworksite-row1-foreman-list-option">Select A Foreman</option>
              {employees?.map((employee: { firstName: string; lastName: string; _id: string }) => {
                return (
                  <option className="worksites-wrapper-sitedetails-addworksite-row1-foreman-list-option" key={employee?._id} value={employee?._id}>
                    {employee?.firstName} {employee?.lastName}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="worksites-wrapper-sitedetails-addworksite-row2">
          <div className="worksites-wrapper-sitedetails-addworksite-row2-employees">
            <label className="worksites-wrapper-sitedetails-addworksite-row2-employees-label" htmlFor="employees">
              Employees
            </label>
            <select
              className="worksites-wrapper-sitedetails-addworksite-row2-employees-list"
              name=""
              id=""
              onChange={e => {
                if (employees) {
                  employees?.map((el: any) => {
                    if (el._id === e.target.value) {
                      setSelectedEmployee({ _id: el._id, firstName: el.firstName, lastName: el.lastName, middleInitial: el.middleInitial });
                    }
                  });
                }
              }}
            >
              <option className="worksites-wrapper-sitedetails-addworksite-row2-employees-list-option" value="default">
                Select An Employee
              </option>
              {employees?.map((employee: { firstName: string; lastName: string; _id: string }) => {
                return (
                  <option className="worksites-wrapper-sitedetails-addworksite-row2-employees-list-option" key={employee?._id} value={employee?._id}>
                    {employee?.firstName} {employee?.lastName}
                  </option>
                );
              })}
            </select>

            <div
              className="worksites-wrapper-sitedetails-addworksite-row2-employees-addbtn"
              onClick={() => {
                setEmployeesToShow([...employeesToShow, selectedEmployee!]);
                setCreateSiteData({
                  ...createSiteData,
                  employees: [...createSiteData?.employees, selectedEmployee],
                });
              }}
            >
              <FontAwesomeIcon className="worksites-wrapper-sitedetails-addworksite-row2-employees-addbtn-icon" icon={faPlus} />
            </div>

            <div className="worksites-wrapper-sitedetails-addworksite-row2-employees-addemployees">
              <ul className="worksites-wrapper-sitedetails-addworksite-row2-employees-addemployees-list">
                {employeesToShow &&
                  employeesToShow?.map((employee: { firstName: string; lastName: string; _id: string }) => {
                    return (
                      <li className="worksites-wrapper-sitedetails-addworksite-row2-employees-addemployees-list-item" key={employee?._id}>
                        <div>
                          {employee?.firstName} {employee?.lastName}{" "}
                        </div>
                        <div></div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <div className="employees-wrapper-workerdetails-form-btngroup">
          <button
            className="employees-wrapper-workerdetails-form-btngroup-savebtn"
            type="submit"
            onClick={e => {
              if (!canAddWorksite) {
                console.log("You cannot do this");
              } else if (canAddWorksite) {
                handleCreateWorksite(e);
              }
              setCanEdit(false);
            }}
          >
            Save
          </button>

          <button
            className="employees-wrapper-workerdetails-form-btngroup-savebtn"
            type="button"
            onClick={e => {
              setCanEdit(false);
              setCanAddWorksite(false);
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    );
  };

  const handleRemoveWorksiteEmployee = (idx: number) => {
    const tempWorkers = [...employeesToShow];
    tempWorkers.splice(idx, 1);
    setEmployeesToShow(tempWorkers);
    setCreateSiteData({
      ...createSiteData,
      employees: tempWorkers,
    });
  };

  const renderUpdateWorksite = () => {
    return (
      <form className="worksites-wrapper-sitedetails-addworksite">
        <h4 className="worksites-wrapper-sitedetails-title">Update Worksite</h4>
        <div className="worksites-wrapper-sitedetails-addworksite-row1">
          <div className="worksites-wrapper-sitedetails-addworksite-row1-job">
            <label className="worksites-wrapper-sitedetails-addworksite-row1-job-label" htmlFor="job">
              Job
            </label>
            <select
              className="worksites-wrapper-sitedetails-addworksite-row1-job-list"
              name="jobName"
              id="jobName"
              onChange={e => {
                if (jobs) {
                  jobs?.map((job: { jobName: string; id: string }) => {
                    if (job.id === e.target.value) {
                      setCreateSiteData({
                        ...createSiteData,
                        job: job,
                        jobId: e.target.value,
                      });
                    }
                  });
                }
              }}
            >
              <option value="default">Select A Job</option>
              {jobs?.map((job: { jobName: string; id: string }) => {
                return (
                  <option className="worksites-wrapper-sitedetails-addworksite-row1-job-list-option" key={job.id} value={job?.id}>
                    {job?.jobName}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="worksites-wrapper-sitedetails-addworksite-row1-foreman">
            <label className="worksites-wrapper-sitedetails-addworksite-row1-foreman-label" htmlFor="foreman">
              Foreman
            </label>
            <select
              className="worksites-wrapper-sitedetails-addworksite-row1-foreman-list"
              name="foremanId"
              id="foremanId"
              onChange={e => {
                if (employees) {
                  employees?.map((el: any) => {
                    if (el._id === e.target.value) {
                      setCreateSiteData({
                        ...createSiteData,
                        foreman: el,
                        foremanId: e.target.value,
                      });
                    }
                  });
                }
              }}
            >
              <option>Select A Foreman</option>
              {employees?.map((employee: { firstName: string; lastName: string; _id: string }) => {
                return (
                  <option className="worksites-wrapper-sitedetails-addworksite-row1-foreman-list-option" key={employee?._id} value={employee?._id}>
                    {employee?.firstName} {employee?.lastName}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="worksites-wrapper-sitedetails-addworksite-row2">
          <div className="worksites-wrapper-sitedetails-addworksite-row2-employees">
            <label className="worksites-wrapper-sitedetails-addworksite-row2-employees-label" htmlFor="employees">
              Employees
            </label>
            <select
              className="worksites-wrapper-sitedetails-addworksite-row2-employees-list"
              name=""
              id=""
              onChange={e => {
                if (employees) {
                  employees?.map((el: any) => {
                    if (el._id === e.target.value) {
                      const selectedEmployeeRate = employeeRates?.find((i: { employeeId: string }, idx: number) => selectedEmployee?._id === i.employeeId);
                      if (selectedEmployeeRate) {
                        setEmployeeHasRate(true);
                      } else {
                        setEmployeeHasRate(false);
                      }
                      setSelectedEmployee({ _id: el._id, firstName: el.firstName, lastName: el.lastName, middleInitial: el.middleInitial });
                    }
                  });
                }
              }}
            >
              <option className="worksites-wrapper-sitedetails-addworksite-row2-employees-list-option" value="default">
                Select An Employee
              </option>
              {employees?.map((employee: { firstName: string; lastName: string; _id: string }, idx: number) => {
                return (
                  <option className="worksites-wrapper-sitedetails-addworksite-row2-employees-list-option" key={employee?._id} value={employee?._id}>
                    {employee?.firstName} {employee?.lastName}
                  </option>
                );
              })}
            </select>

            <div
              className="worksites-wrapper-sitedetails-addworksite-row2-employees-addbtn"
              onClick={() => {
                if (!selectedEmployee) {
                  alert("No employee selected. Select an employee first, then click the Plus (+) button.");
                  return;
                }
                if (!employeeHasRate) {
                  if (window.confirm(`${selectedEmployee?.firstName} ${selectedEmployee?.lastName} does not have hourly rates set.  Would you like to set this employee's hourly rates now?`)) {
                    setCanEdit(false);
                    navigation("/employee-job-rates");
                    setSelectedEmployee(null);
                  } else {
                    alert(`No hourly rate.  ${selectedEmployee?.firstName} ${selectedEmployee?.lastName} cannot be added to this worksite.`);
                    setSelectedEmployee(null);
                    return;
                  }
                } else {
                  setEmployeesToShow([...employeesToShow, selectedEmployee!]);
                  setCreateSiteData({
                    ...createSiteData,
                    employees: [...createSiteData?.employees, selectedEmployee],
                  });
                  setSelectedEmployee(null);
                }
              }}
            >
              <FontAwesomeIcon className="worksites-wrapper-sitedetails-addworksite-row2-employees-addbtn-icon" icon={faPlus} />
            </div>

            <div className="worksites-wrapper-sitedetails-addworksite-row2-employees-addemployees">
              <ul className="worksites-wrapper-sitedetails-addworksite-row2-employees-addemployees-list">
                {employeesToShow &&
                  employeesToShow?.map((employee: { firstName: string; lastName: string; _id: string }, idx) => {
                    return (
                      <li className="worksites-wrapper-sitedetails-addworksite-row2-employees-addemployees-list-item" key={employee?._id}>
                        <div>
                          {employee?.firstName} {employee?.lastName}{" "}
                        </div>

                        <div className="worksites-wrapper-sitedetails-addworksite-row2-employees-addemployees-list-item-removebtn">
                          <FontAwesomeIcon className="worksites-wrapper-sitedetails-addworksite-row2-employees-addemployees-list-item-removebtn-icon" icon={faMinusCircle} onClick={() => handleRemoveWorksiteEmployee(idx)} />
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>

          <div className="worksites-wrapper-sitedetails-addworksite-row2-archive">
            <label className="worksites-wrapper-sitedetails-addworksite-row2-archive-label">Archive?</label>
            <button
              name="shouldVerifyId"
              className="worksites-wrapper-sitedetails-addworksite-row2-archive-input"
              disabled={!canEdit}
              type="button"
              onClick={() => {
                setSelectedSite({
                  ...selectedSite,
                  archive: selectedSite?.archive === undefined ? true : !selectedSite?.archive,
                });
                setCreateSiteData({
                  ...createSiteData,
                  archive: selectedSite?.archive === undefined ? true : !selectedSite?.archive,
                });
              }}
            >
              {selectedSite?.archive ? "True" : "False"}
            </button>
          </div>
        </div>
        <div className="worksites-wrapper-sitedetails-addworksite-btngroup">
          <button
            className="worksites-wrapper-sitedetails-addworksite-btngroup-savebtn"
            type="submit"
            onClick={e => {
              if (!canEdit) {
                console.log("You cannot do this");
              } else if (canEdit) {
                handleModifyWorksite(e);
                alert("worksite Successfully Updated");
                setSelectedEmployee(null);
              }
              setCanEdit(false);
              // document.location.reload();
            }}
          >
            Save
          </button>

          <button
            className="worksites-wrapper-sitedetails-addworksite-btngroup-savebtn"
            type="button"
            onClick={e => {
              setCanEdit(false);
              setCanAddWorksite(false);
              setSelectedEmployee(null);
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    );
  };

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }

  return (
    <div className="worksites">
      <AdminMenu />
      <div className="worksites-btngrp">
        <div
          className={`worksites-btngrp-addworksite ${canEdit && "addemployeeclicked"}`}
          onClick={e => {
            setCanEdit(false);
            setCanAddWorksite(true);
          }}
        >
          <p className="worksites-btngrp-addworksite-text">Add Worksite</p>
          <FontAwesomeIcon className="worksites-btngrp-addworksite-button" icon={faPlus} />
        </div>

        <div className="worksites-btngrp-filter">
          <label className="worksites-btngrp-filter-label" htmlFor="filter">
            Filter Jobs
          </label>
          <select
            className="worksites-btngrp-filter-selector"
            name="filter"
            id="filter"
            onChange={e => {
              setShowArchived(e.target.value);
            }}
          >
            <option id="filter-option" className="worksites-btngrp-filter-selector-option" value="active">
              Active
            </option>
            <option className="worksites-btngrp-filter-selector-option" value="archived">
              Archived
            </option>
          </select>
        </div>

        {getUser().level === 1 && (
          <button
            className={`worksites-btngrp-removebtn ${selectedSite && "remove-selected"}`}
            type="button"
            onClick={() => {
              handleDeleteWorksite(selectedSite?.id!);
            }}
          >
            Remove Selected Job
          </button>
        )}
      </div>

      <button
        className={`worksites-jobsratesbtn ${selectedSite !== null && "editbtnactive"}`}
        type="button"
        onClick={() => {
          navigation("/employee-job-rates");
        }}
      >
        Employee Job Rates
      </button>
      <div className="worksites-wrapper">
        <>{showArchived === "active" ? renderWorksitelist() : renderArchivedWorksitelist()}</>
        <>{renderWorksiteDetails()}</>
      </div>
    </div>
  );
};

export default Worksites;
