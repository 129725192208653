import React, { useEffect, useState } from "react";

import { getShiftData, getFocus, updateCostCode } from "../../api/si";
import { getCostCodes } from "../../graphql/queries";

import "./WorkersListWr.scss";

type employee = {
  id: string;
  firstName: string;
  lastName: string;
  last4SSN: string;
  class: string;
  doubleTime?: number | string;
  costcode: string;
};

interface WorkerListProps {
  employees: [employee];
  notesModal: (firstName: string, lastName: string, id: string, renderNotesModal: boolean, notesValue?: string | number | undefined) => void;
  notesValue?: string;
  onSaveDT?: (firstName: string, lastName: string, id: string, type: string, renderDTModal: boolean) => void;
  shift?: string;
  workType?: string;
}

const WorkersListWr: React.FC<WorkerListProps> = ({ employees, notesModal, onSaveDT, notesValue, shift, workType }) => {
  const [regularTime, setRegularTime] = useState<number | null>(null);
  const [doubleTimeHours, setDoubleTimeHours] = useState<number | null>(null);
  const [doubleTime, setDoubleTime] = useState<number | null>(doubleTimeHours! * 2);
  const [showDTModal, setShowDTModal] = useState<boolean>(false);
  const [siteEmployees, setSiteEmployees] = useState<any | null>(null);
  const [costCode, setCostCode] = useState<string>("");
  const [laborCode, setLaborCode] = useState<string>("");
  const [costCodes, setCostCodes] = useState<any>([]);

  const fetchShiftData = async () => {
    const focus = await getFocus();
    const updatedShiftData = await getShiftData(focus);
    setSiteEmployees(updatedShiftData?.siteEmployees.employees);
  };

  const fetchCostCodes = async () => {
    const data = await getCostCodes();
    setCostCodes(data.costCodes);
  };
  // console.log('COST CODES: ', costCodes.costCodes);

  useEffect(() => {
    if (navigator.onLine) {
      fetchCostCodes();
    }
  }, [employees, navigator.onLine && getCostCodes]);

  useEffect(() => {
    fetchShiftData();
    setRegularTime(7);
    // setSiteEmployees(employees);
  }, [employees]);

  const padTo2Digits = (num: number) => num.toString().padStart(2, "0");

  const convertMsToHM = (milliseconds: number) => {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    // 👇️ if seconds are greater than 30, round minutes up (optional)
    minutes = seconds >= 30 ? minutes + 1 : minutes;

    minutes = minutes % 60;

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    hours = hours % 24;

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
  };

  const calculateRegHours = (timein: Date, timeout: Date) => {
    const t1 = new Date(timein).getTime();
    const t2 = new Date(timeout).getTime();
    let diff = (t1 - t2) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
  };

  return (
    <div className="workersWr">
      <ul className="workersWr-list">
        {siteEmployees?.map((employee: any) => {
          return (
            <li className="workersWr-list-item" key={employee.id}>
              {/* <input
                type="text"
                className="workers-list-item-costcode"
                value={employee.costcode}
                onChange={(e) => setCostCode(e.target.value)}
                onBlur={async () => await updateCostCode(await getFocus(), employee.id, costCode)}
                placeholder="Cost Code"
              /> */}
              <select
                className="workers-list-item-costcode"
                value={employee.costcode !== null ? employee.costcode : "Cost Code"}
                onChange={async e => {
                  const costAndLabor = costCodes.find((c: any) => c.costcode === e.target.value);
                  setLaborCode(costAndLabor.laborCode);
                  setCostCode(e.target.value);
                  const data = await updateCostCode(await getFocus(), employee.id, costCode, laborCode);
                }}
                onBlur={async () => {
                  await updateCostCode(await getFocus(), employee.id, costCode, laborCode);
                  window.location.reload();
                }}
                disabled={!navigator.onLine || shift === "0" || shift === undefined || !workType || workType === undefined}
              >
                <option className="workers-list-item-costcode-defaultoption" value="">
                  Cost
                </option>
                {costCodes?.length > 0 &&
                  costCodes.map((code: any, idx: number) => {
                    return (
                      <option className={`workers-list-item-costcode-option costcodeoption${idx}`} style={{ width: "10%" }} key={idx} value={code.costCode}>
                        <p style={{ width: "200px" }}>{code.costCode}</p> | <p>{code.description}</p>
                      </option>
                    );
                  })}
              </select>
              <div className="workersWr-list-item-name">
                {employee.firstName} {employee.lastName} {employee.middleInitial}
              </div>
              <div className="workersWr-list-item-last4ssn">{employee.last4SSN}</div>
              <div className="workersWr-list-item-class">{employee.class}</div>
              <div
                className={`workersWr-list-item-timein ${employee.timeIn && "employeein"}`}
                onClick={() => {
                  console.log("Clicked...");
                }}
              >
                {employee.timeIn ? <span className="workersWr-list-item-timein-time">{employee?.timeOut && calculateRegHours(employee?.timeIn, employee?.timeOut) < 7 ? calculateRegHours(employee?.timeIn, employee?.timeOut) : regularTime}</span> : <span className="workersWr-list-item-timein-placeholder">Hours</span>}
              </div>
              <div
                className={`workersWr-list-item-doubletime ${employee.doubleTime && "employeedt"}`}
                onClick={() => {
                  if (!employee.timeIn) {
                    alert("Employee Must Enter Time-In");
                  } else {
                    if (onSaveDT) {
                      onSaveDT(employee.firstName, employee.lastName, employee._id, "doubleTime", true);
                    }
                  }
                }}
              >
                {employee?.doubleTime ? <span className="workersWr-list-item-doubletime-time">{employee?.doubleTime}</span> : <span className="workersWr-list-item-doubletime-placeholder">{employee.doubleTime ? employee.doubleTime : "DT"}</span>}
              </div>
              <div
                className={`workersWr-list-item-notes ${employee.notes && "employeenotes"}`}
                onClick={() => {
                  if (shift === "0" || shift === undefined) {
                    alert("You have to select a shift");
                    return;
                  } else if (!workType || workType === undefined) {
                    alert("You have to select a work type.");
                    return;
                  } else {
                    notesModal(employee.firstName, employee.lastName, employee?._id, true, employee.notes);
                  }
                }}
              >
                Notes
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default WorkersListWr;
