import React, { useState, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getMaterialsList, getMaterialsList2, getMaterials, createMaterialsBudget, updateMaterialsBudget } from "../../../graphql/queries";
import { useMutation, useQuery } from "@apollo/client";
import { faMinus, faMinusCircle, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Budget.scss";
import AdminMenu from "../AdminMenu";
import { materialsDesc } from "../../../api/si";
import { getUser, logout } from "../../../api/auth";

enum ImageActions {
  addImages = "Add Image",
  viewImages = "View Images",
}

const imageActions = [ImageActions.addImages, ImageActions.viewImages];

const Budget: React.FC = () => {
  const [materialsList, setMaterialslist] = useState<any>(null);
  const [selectedMaterials, setSelectedMaterials] = useState<any>(null);
  const [selectedItemToEdit, setSelectedItemToEdit] = useState<any>(null);
  const [materialsDesc, setMaterialsDesc] = useState<any[]>([
    {
      qty: 0,
      um: "",
      unitCost: 0,
      totalCost: 0,
      item: "",
      description: "",
      images: [],
      workzone: "",
    },
  ]);
  const [nextOffset, setNextOffset] = useState<number>(10);
  const [prevOffset, setPrevOffset] = useState<number>(0);
  const [editMaterials, setEditMaterials] = useState<boolean>(false);
  const [selectedImageAction, setImageAction] = useState<string | null>(null);

  const [modifyMaterials, { data: modifyJobData, loading: modifyJobLoading, error: modifyJobError }] = useMutation(updateMaterialsBudget);

  const [addMaterials, { data: addJobData, loading: addJobLoading, error: addJobError }] = useMutation(createMaterialsBudget);

  const { data: materialsListData, fetchMore } = useQuery(getMaterialsList2, {
    variables: {
      limit: 10,
      offset: 0,
    },
  });

  const navigate = useNavigate();

  const fetchMaterials = async () => {
    const m = await getMaterialsList();
    // setMaterialslist({ ...m });
    setMaterialslist({ ...materialsListData });
  };

  useEffect(() => {
    fetchMaterials();
    localStorage.removeItem("materialImageAction");
  }, [materialsListData]);

  const totalCostRef = useRef([]);
  totalCostRef.current = [];
  let arr = [];
  const addToRef = (el: { value: never }) => {
    if (el && !totalCostRef.current.includes(el.value)) {
      totalCostRef.current.push(el.value);
    }
    arr = totalCostRef.current;
    let len = materialsDesc.length;
    for (let i = 0; i < len; i++) {
      materialsDesc[i].totalCost = parseFloat(arr[i]);
    }
  };

  const addMaterialDescRow = () => {
    const item = {
      qty: 0,
      um: "",
      unitCost: 0,
      totalCost: 0,
      item: "",
      description: "",
      images: [],
      workzone: "",
    };
    setSelectedMaterials({
      ...selectedMaterials,
      materials: [item, ...selectedMaterials.materials!],
    });
  };

  const removeMaterialDescRow = () => {
    // setMaterialsDesc(materialsDesc.slice(0, -1));
  };

  const handleSaveUpdate = () => {
    const tempArr = [...materialsList.materialsList];
    const idx = tempArr.findIndex((t: any) => t.id === selectedMaterials.id);
    tempArr.splice(idx, 1, selectedMaterials);
    setMaterialslist({
      ...materialsList,
      materialsList: tempArr,
    });
  };

  const handleJobImageView = (view: string, idx: number, imgIds: string[]) => {
    if (view === "Add Image") {
      setImageAction(view);
      localStorage.setItem("materialImageAction", JSON.stringify({ view, idx, imgIds, from: "admin" }));
    } else if (view === "View Images") {
      setImageAction(view);
      localStorage.setItem("materialImageAction", JSON.stringify({ view, idx, imgIds, from: "admin" }));
    }
    navigate("/job-images");
  };

  const renderMaterials = () => {
    return materialsList?.materialsList2?.map((list: any, idx: number) => {
      return (
        <div
          className="budget-mlist"
          key={idx}
          onClick={() => {
            setSelectedMaterials({ ...list });
          }}
        >
          <div className="budget-mlist-header">
            <p className="budget-mlist-header-jobname">JOB: {list.jobName}</p>
            <p className="budget-mlist-header-shift">SHIFT: {list.shift}</p>
            <p className="budget-mlist-header-date">DATE: {new Date(list?.date).toDateString()}</p>
            <p
              className="budget-mlist-header-editrow"
              onClick={() => {
                setEditMaterials(true);
                setSelectedItemToEdit({ ...list });
              }}
            >
              EDIT
              <FontAwesomeIcon className="budget-mlist-header-editrow-icon" icon={faPen} />
            </p>
            {/* {list.id === materialsList.materialsList[idx].id && editMaterials && (
              <p className="budget-mlist-header-addrow">
                ADD ROW
                <FontAwesomeIcon className="budget-mlist-header-addrow-icon" icon={faPlus} onClick={addMaterialDescRow} />
              </p>
            )} */}
          </div>

          {/* <div className="budget-mlist-materialdesc-add">
            <FontAwesomeIcon className="budget-mlist-materialdesc-add-icon" icon={faPlus} onClick={addMaterialDescRow} />
          </div> */}
          <table
            className="budget-mlist-materialdesc"
            // onBlur={() => {
            //   handleSaveMaterialDesc();
            // }}
          >
            <thead className="budget-mlist-materialdesc-header">
              <tr className="budget-mlist-materialdesc-header-rows">
                <th className="budget-mlist-materialdesc-header-rows-item">ITEM</th>
                <th className="budget-mlist-materialdesc-header-rows-item">QTY</th>
                <th className="budget-mlist-materialdesc-header-rows-item">UM</th>
                <th className="budget-mlist-materialdesc-header-rows-item">Material Description and/or Deliveries</th>
                <th className="budget-mlist-materialdesc-header-rows-item">UNIT COST</th>
                <th className="budget-mlist-materialdesc-header-rows-item">TOTAL COST</th>
                <th className="budget-mlist-materialdesc-header-rows-item">WORK ZONE</th>
                <th className="budget-mlist-materialdesc-header-rows-item">IMAGES</th>
              </tr>
            </thead>
            <tbody className="budget-mlist-materialdesc-body">
              {list?.materials?.map((item: materialsDesc, index: number) => {
                return (
                  <tr key={index} className="budget-mlist-materialdesc-body-container">
                    <td className="budget-mlist-materialdesc-body-container-item tdMatQty">
                      <div className="budget-mlist-tasks-table-row-content-input matQtyInput">{item?.item}</div>
                    </td>
                    <td className="budget-mlist-materialdesc-body-container-item tdMatQty">
                      <div className="budget-mlist-tasks-table-row-content-input matQtyInput">{item?.qty}</div>
                    </td>
                    <td className="budget-mlist-materialdesc-body-container-item tdMatUM">
                      <div className="budget-mlist-tasks-table-row-content-input matUMInput">{item?.um}</div>
                    </td>
                    <td className="budget-mlist-materialdesc-body-container-item tdMatDesc">
                      <div className="budget-mlist-tasks-table-row-content-input matDescInput">{item?.description}</div>
                    </td>
                    <td className="budget-mlist-materialdesc-body-container-item tdUnitCost">
                      <div className="budget-mlist-tasks-table-row-content-input matQtyInput">{item?.unitCost === 0 || item?.unitCost === null || isNaN(item?.unitCost) ? "" : item?.unitCost}</div>
                    </td>
                    <td className="budget-mlist-materialdesc-body-container-item tdTotalCost">
                      <div className="budget-mlist-tasks-table-row-content-input matQtyInput">{item?.totalCost !== 0 || item?.totalCost !== null || !isNaN(item?.totalCost) ? item?.unitCost * item?.qty : ""}</div>
                    </td>
                    <td className="budget-mlist-materialdesc-body-container-item tdTotalCost">{item?.workzone ? item?.workzone : "N/A"}</td>
                    <td className="budget-mlist-materialdesc-body-container-item tdTotalCost">{item?.images ? item?.images.length : 0}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    });
  };

  const renderMaterialsEdit = () => {
    return (
      <div className="budget-mlist">
        <div className="budget-mlist-header">
          <p className="budget-mlist-header-jobname">JOB: {selectedMaterials.jobName}</p>
          <p className="budget-mlist-header-shift">SHIFT: {selectedMaterials.shift}</p>
          <p className="budget-mlist-header-date">DATE: {new Date(selectedMaterials?.date).toDateString()}</p>
          <p
            className="budget-mlist-header-editrow"
            onClick={() => {
              setEditMaterials(false);
              handleSaveUpdate();
            }}
          >
            DONE
            <FontAwesomeIcon className="budget-mlist-header-editrow-icon" icon={faPen} />
          </p>
          <p className="budget-mlist-header-addrow" onClick={addMaterialDescRow}>
            ADD ROW
            <FontAwesomeIcon className="budget-mlist-header-addrow-icon" icon={faPlus} />
          </p>
        </div>
        <table className="budget-mlist-materialdesc">
          <thead className="budget-mlist-materialdesc-header">
            <tr className="budget-mlist-materialdesc-header-rows">
              <th className="budget-mlist-materialdesc-header-rows-item">ITEM</th>
              <th className="budget-mlist-materialdesc-header-rows-item">QTY</th>
              <th className="budget-mlist-materialdesc-header-rows-item">UM</th>
              <th className="budget-mlist-materialdesc-header-rows-item">Material Description and/or Deliveries</th>
              <th className="budget-mlist-materialdesc-header-rows-item">UNIT COST</th>
              <th className="budget-mlist-materialdesc-header-rows-item">TOTAL COST</th>
              <th className="budget-mlist-materialdesc-header-rows-item">WORK ZONE</th>
              <th className="budget-mlist-materialdesc-header-rows-item">ATTACHMENTS</th>
              <th className="budget-mlist-materialdesc-header-rows-item"></th>
            </tr>
          </thead>
          <tbody className="budget-mlist-materialdesc-body">
            {selectedMaterials?.materials?.map((item: materialsDesc, index: number) => {
              return (
                <tr key={index} className="budget-mlist-materialdesc-body-container">
                  <td className="budget-mlist-materialdesc-body-container-item tdMatQty">
                    <input
                      className="budget-mlist-tasks-table-row-content-input matQtyInput"
                      id="matQty"
                      type="string"
                      name="item"
                      value={item?.item}
                      onChange={e => {
                        const updatedItem = selectedMaterials?.materials.map((m: any, idx: number) => {
                          if (idx === index) {
                            let i = { ...m };
                            i.item = e.target.value;
                            return i;
                          } else return m;
                        });
                        setSelectedMaterials({
                          ...selectedMaterials,
                          materials: updatedItem,
                        });
                      }}
                    />
                  </td>
                  <td className="budget-mlist-materialdesc-body-container-item tdMatQty">
                    <input
                      className="budget-mlist-tasks-table-row-content-input matQtyInput"
                      id="matQty"
                      type="number"
                      name="qty"
                      value={item?.qty === 0 ? "" : item?.qty}
                      onChange={e => {
                        const updatedItem = selectedMaterials?.materials.map((m: any, idx: number) => {
                          if (idx === index) {
                            let i = { ...m };
                            i.qty = parseInt(e.target.value);
                            return i;
                          } else return m;
                        });

                        setSelectedMaterials({
                          ...selectedMaterials,
                          materials: updatedItem,
                        });
                      }}
                      step="0.001"
                    />
                  </td>
                  <td className="budget-mlist-materialdesc-body-container-item tdMatUM">
                    <input
                      key={index}
                      className="budget-mlist-tasks-table-row-content-input matUMInput"
                      type="text"
                      name="um"
                      value={item?.um}
                      onChange={e => {
                        const updatedItem = selectedMaterials?.materials.map((m: any, idx: number) => {
                          if (idx === index) {
                            let i = { ...m };
                            i.um = e.target.value;
                            return i;
                          } else return m;
                        });
                        setSelectedMaterials({
                          ...selectedMaterials,
                          materials: updatedItem,
                        });
                      }}
                    />
                  </td>
                  <td className="budget-mlist-materialdesc-body-container-item tdMatDesc">
                    <textarea
                      key={index}
                      className="budget-mlist-tasks-table-row-content-input matDescInput"
                      name="description"
                      value={item?.description}
                      onChange={e => {
                        const updatedItem = selectedMaterials?.materials.map((m: any, idx: number) => {
                          if (idx === index) {
                            let i = { ...m };
                            i.description = e.target.value;
                            return i;
                          } else return m;
                        });
                        setSelectedMaterials({
                          ...selectedMaterials,
                          materials: updatedItem,
                        });
                      }}
                    />
                  </td>
                  <td className="budget-mlist-materialdesc-body-container-item tdUnitCost">
                    <input
                      className="budget-mlist-tasks-table-row-content-input matQtyInput"
                      id="matUnitCost"
                      type="number"
                      name="unitCost"
                      value={item?.unitCost === 0 || item?.unitCost === null || isNaN(item?.unitCost) ? "" : item?.unitCost}
                      onChange={e => {
                        const updatedItem = selectedMaterials?.materials.map((m: any, idx: number) => {
                          if (idx === index) {
                            let i = { ...m };
                            i.unitCost = parseFloat(e.target.value);
                            return i;
                          } else return m;
                        });
                        setSelectedMaterials({
                          ...selectedMaterials,
                          materials: updatedItem,
                        });
                      }}
                      step="0.001"
                    />
                  </td>
                  <td className="budget-mlist-materialdesc-body-container-item tdTotalCost">
                    <input className="budget-mlist-tasks-table-row-content-input matQtyInput" id="totalCost" type="number" name="totalCost" value={item?.totalCost !== 0 || item?.totalCost !== null || !isNaN(item?.totalCost) ? item?.unitCost * item?.qty : ""} step="0.001" readOnly ref={(val: never) => addToRef(val)} />
                  </td>
                  <td className="budget-mlist-materialdesc-body-container-item tdTotalCost">WORK ZONE</td>
                  <td className="budget-mlist-materialdesc-body-container-item tdTotalCost">
                    <div>
                      <div>{item?.images.length}</div>
                      <select
                        className="workreport-main-tasks-table-row-content-input matQtyInput"
                        name="imageActions"
                        id="imageActions"
                        onChange={e => {
                          handleJobImageView(e.target.value, index, item?.images);
                        }}
                        value={selectedImageAction ? selectedImageAction : ""}
                      >
                        <option value="none" selected>
                          Actions
                        </option>
                        {imageActions.map((a: string, idx: number) => {
                          return (
                            <option key={idx} value={a}>
                              {a}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </td>
                  <td className="budget-mlist-materialdesc-body-container-item tdTotalCost">
                    <FontAwesomeIcon
                      className={`jobs-jobs-jobdetails-row5-markups-table-body-row-element-minusicon`}
                      icon={faMinusCircle}
                      onClick={() => {
                        const updatedItem = [...selectedMaterials.materials];
                        updatedItem.splice(index, 1);
                        setSelectedMaterials({
                          ...selectedMaterials,
                          materials: updatedItem,
                        });
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const handleNextPage = async () => {
    const nextData = await fetchMore({
      variables: {
        offset: nextOffset,
      },
    });

    setNextOffset(nextOffset + 10);

    if (nextData?.data) {
      setMaterialslist(nextData?.data);
    }
  };

  const handlePreviousPage = async () => {
    const prevData = await fetchMore({
      variables: { offset: nextOffset - 10 },
      updateQuery(previousData, { fetchMoreResult, variables: { offset } }) {
        const updatedData = previousData.materialsList2.slice(0);
        // for (let i = 0; i < fetchMoreResult.materialsList2.length; ++i) {
        //   updatedData[offset + i] = fetchMoreResult.materialsList2[i];
        // }
        return { ...previousData, materialsList2: updatedData };
      },
    });
    setMaterialslist(prevData?.data);
    setNextOffset(nextOffset - 10);
  };

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }

  if (getUser() && getUser().role !== "ADMIN") {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div className="budget">
      <AdminMenu />
      <div>
        <div className="budget-pagenav">
          <button
            className="budget-pagenav-prevbtn"
            onClick={() => {
              handlePreviousPage();
            }}
          >
            Previous Page
          </button>

          <button
            className="budget-pagenav-nextbtn"
            onClick={async () => {
              handleNextPage();
            }}
          >
            Next Page
          </button>
        </div>

        {!editMaterials ? renderMaterials() : renderMaterialsEdit()}
      </div>
    </div>
  );
};

export default Budget;
