import React from "react";
import { fieldManual, IFieldManualItem } from "../../api/manual";

import "./FieldManual.scss";

const FieldManual = () => {
  return (
    <div className="fieldmanual">
      <div className="fieldmanual-header">
        <h1 className="fieldmanual-header-title">Field Manual</h1>
      </div>

      <ul className="fieldmanual-main">
        {fieldManual.map((m: IFieldManualItem, idx: number) => (
          <li className="fieldmanual-main-item" key={idx}>
            <h2 className="fieldmanual-main-item-title">{m.title}</h2>
            <h3 className="fieldmanual-main-item-description ">{m.description}</h3>
            <ul className="fieldmanual-main-item-textlist">
              {m.textArr.map((t: any, idx) => (
                <li className="fieldmanual-main-item-textlist-item" key={idx}>
                  {typeof t === "string" && t}
                  {typeof t === "object" && (
                    <ul className="fieldmanual-main-item-textlist-item-sublist">
                      {t.map((el: any, idx: string) => (
                        <li className="fieldmanual-main-item-textlist-item-sublist-item" key={idx}>
                          {el}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FieldManual;
