import React, { useEffect, useState, useRef } from "react";
import { getUser } from "../../api/auth";
import { Navigate, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { faChevronDown, faChevronUp, faPlus, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SignatureCanvas from "react-signature-canvas";
import Button1 from "../../common/button1/Button1";
import Header from "../../common/header/Header";
import Modal from "../../common/modal/Modal";
import PdfViewer from "../../common/pdfViewer/PdfViewer";

import WorkersListWr from "../workersListWr/WorkersListWr";

import { focusType, getFocus, getShiftData, materialsDesc, tasksType, updateEmployeeDoubleTime, updateEmployeeDoubleTimeSignature, updateNotesSignature, updateIncidentReportQ, updateIncidentReportText, updateMaterialDesc, updateTasks, updateRemarks, updateWorkDescription, updateContractorRepName, updateContractorRepSignature, updateAgencyRepName, updateAgencyRepSignature, updatePMSignature, updatePMName, updateChangeOrderTitle } from "../../api/si";
import { getForemanData, createSigninSignoutWr, createTimesheet } from "../../graphql/queries";

import "./WorkReport.scss";

const menuList = [
  { name: "Employees" },
  { name: "Work Description" },
  { name: "Materials/Deliveries" },
  { name: "Incident Report" },
  // { name: "Remarks" },
  { name: "Approvals" },
  { name: "Tasks" },
];

const taskProgressList = [
  { name: "Starting", value: "STARTING" },
  { name: "In Progress", value: "PROGRESS" },
  { name: "Completed", value: "COMPLETED" },
];

type EmployeeType = {
  class: string;
  firstName: string;
  id: string;
  last4SSN: string;
  lastName: string;
  middleInitial: string;
  timeIn: string;
  timeInSignature: string;
  __typename?: string;
};

type dtModalDataType = {
  firstName: string;
  lastName: string;
  id: string;
  type: string;
};

enum ImageActions {
  addImages = "Add Image",
  viewImages = "View Images",
  addImageAttachment = "Add Attachment",
}

const imageActions = [ImageActions.addImages, ImageActions.viewImages, ImageActions.addImageAttachment];

const WorkReport: React.FC = () => {
  const [selectedShift, setSelectedShift] = useState<string | null>(null);
  const [siteFocus, setSiteFocus] = useState<focusType | null>(null);
  const [shiftData, setShiftData] = useState<any | null>(null);
  const [foreman, setForeman] = useState<any | null>(null);
  const [selectedImageAction, setImageAction] = useState<string | null>(null);

  const [shouldSaveDesc, setShouldSaveDesc] = useState<boolean>(false);

  const [showMaterialDesc, setShowMaterialDesc] = useState<boolean>(false);
  const [showWorkDesc, setShowWorkDesc] = useState<boolean>(false);
  const [showRemarks, setShowRemarks] = useState<boolean>(false);
  const [showEmployees, setShowEmployees] = useState<boolean>(false);
  const [showIncidentRep, setShowIncidentRep] = useState<boolean>(false);
  const [showApprovals, setShowApprovals] = useState<boolean>(false);
  const [showTasks, setShowTasks] = useState<boolean>(false);
  const [showChangeOrderTitle, setShowChangeOrderTitle] = useState<boolean>(false);
  const [showDoubletimeModal, setShowDoubletimeModal] = useState<boolean>(false);
  const [showAttachmentsModal, setShowAttachmentModal] = useState<boolean>(false);
  const [dtModalData, setDtModalData] = useState<dtModalDataType | null>();
  const [showNotesModal, setShowNotesModal] = useState<boolean | null>(false);
  const [notesModalData, setNotesModalData] = useState<any | null>(null);
  const [notes, setNotes] = useState<string | null>(null);
  const [pdfAttachmnt, setPdfAttachmnt] = useState<any | null>(null);
  const [workTasks, setWorkTasks] = useState<tasksType[]>([
    {
      title: "",
      status: "",
      progress: "",
      details: "",
    },
  ]);

  const [materialsDesc, setMaterialsDesc] = useState<any[]>([
    {
      qty: 0,
      um: "",
      unitCost: 0,
      totalCost: 0,
      item: "",
      description: "",
      workZone: "",
      images: [],
    },
  ]);

  const navigate = useNavigate();

  const [createSI, { data, loading, error }] = useMutation(createSigninSignoutWr);

  const handleAddSigninSignoutWr = async () => {
    const focus = await getFocus();
    setSiteFocus(focus);
    const _shiftData = await getShiftData(focus);
    setShiftData(_shiftData);
    delete shiftData.__typename;
    delete shiftData.id;
    // delete shiftData.job.agency?.__typename;
    shiftData?.siteEmployees.employees.forEach((employee: any) => {
      delete employee.__typename;
      if (!employee.doubleTime) {
        employee.doubleTime = 0.5;
      }
    });
    shiftData.canRecall = true;
    shiftData.hasBeenRecalled = false;
    shiftData.foreman = shiftData.siteEmployees.foreman.firstName + " " + shiftData.siteEmployees.foreman.lastName;
    shiftData.siteEmployees = [...shiftData?.siteEmployees.employees];
    delete shiftData?.siteEmployees.employees;
    delete shiftData?.docPrefix;
    // delete shiftData?.markups;
    delete shiftData?.shouldVerifyId;
    delete shiftData?.laborTicketAbv;
    shiftData.startDate = new Date();
    shiftData.taskCompletionDate = new Date();

    delete shiftData.job;

    shiftData.signatureDate = new Date().toDateString();

    // const workerHrsArr: any[] = [];
    // shiftData.siteEmployees.forEach((employee: any) => {
    //   workerHrsArr.push({
    //     name: `${employee.firstName} ${employee.lastName}`,
    //     id: employee.id,
    //     workerClass: employee.class,
    //     regHours: employee.regularTime,
    //     otHours: !employee.doubleTime && employee.regHours === 7 ? 0.5 : employee.doubleTime,
    //     laborCode: employee.laborCode,
    //     costCode: employee.costCode,
    //     percentage: 0.08
    //   });
    // });
    // createTS({
    //   variables: {
    //     input: {
    //       jobName: shiftData.jobName,
    //       jobId: shiftData.job.id,
    //       weekEnding: shiftData.taskCompletionDate,
    //       reportNumber: shiftData.reportNo,
    //       reportHours: workerHrsArr,
    //     }
    //   }
    // })

    createSI({ variables: { input: shiftData } });

    alert("Work Report Successfully Submitted");
    setShiftData(null);
  };

  const fetchShiftData = async () => {
    const focus = await getFocus();
    setSiteFocus(focus);
    const data = await getShiftData(focus);
    setShiftData(data);
    if (data?.materialsDesc && data?.materialsDesc.length > 0) {
      setMaterialsDesc([...data?.materialsDesc]);
    }
    if (data?.tasks && data?.tasks.length > 0) {
      setWorkTasks([...data?.tasks]);
    }
  };

  useEffect(() => {
    fetchShiftData();
    getForemanData(getUser()?.userId)
      .then(worker => {
        setForeman(worker);
      })
      .catch(error => {
        console.log({ ERROR: error.message });
      });
    localStorage.removeItem("materialImageAction");
  }, []);

  const renderEmployees = () => {
    return (
      <WorkersListWr
        shift={shiftData?.shift}
        workType={shiftData?.workType}
        employees={shiftData?.siteEmployees.employees}
        notesModal={(firstName: string, lastName: string, id: string, renderNotesModal: boolean, notesValue) => {
          setNotesModalData({
            firstName,
            lastName,
            id,
            notesValue,
          });
          setShowNotesModal(renderNotesModal);
        }}
        onSaveDT={(firstName, lastName, id, type, renderDTModal) => {
          renderDoubleTimeModal(firstName, lastName, id, type, renderDTModal);
        }}
      />
    );
  };

  const handleNotesSignature = async (notes: string) => {
    const newShiftData = await updateNotesSignature(siteFocus!, notesModalData.id, notes);
    setShiftData(newShiftData);
  };

  const getNotesValue = (id: string) => {
    const employeeNote = shiftData?.siteEmployees.employees.find((e: { _id: string }) => e._id === id);
    return employeeNote.notes;
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>, id: string) => {
    setNotes(e.target.value);
    const employees = shiftData?.siteEmployees.employees.map((employee: { id: string }) => {
      if (employee.id === id) {
        const updatedEmployee = {
          ...employee,
          notes: e.target.value,
        };
        return updatedEmployee;
      }
      return employee;
    });
    const updatedShift = {
      ...shiftData,
      employees,
    };
    setShiftData(updatedShift);
  };

  const renderNotesModal = () => {
    return (
      <Modal
        modalType="notes"
        notesModalData={notesModalData}
        handleNotesChange={e => handleNotesChange(e, notesModalData?.id)}
        onSaveNotes={() => {
          setShowNotesModal(false);
          handleNotesSignature(notes!);
        }}
        notesValue={getNotesValue(notesModalData?.id)}
        onSaveTime={() => console.log("notes modal time")}
        onCancelNotes={() => {
          setShowNotesModal(false);
        }}
      />
    );
  };

  const renderDoubleTimeModal = (firstName: string, lastName: string, id: string, type: string, renderDTModal: boolean) => {
    const data = {
      firstName,
      lastName,
      id,
      type,
    };
    setDtModalData(data);
    setShowDoubletimeModal(renderDTModal);
    return;
  };

  const saveDoubleTimeHours = async (time: string) => {
    console.log("DT MODAL DATA: ", dtModalData);
    const focus = await getFocus();
    const updatedShiftData = await updateEmployeeDoubleTime(focus, dtModalData?.id!, parseFloat(time));
    setShiftData(updatedShiftData);
  };

  const saveDoubleTimeSignature = async (sig: string) => {
    const focus = await getFocus();
    const updatedShiftData = await updateEmployeeDoubleTimeSignature(focus, dtModalData?.id!, sig);
    setShiftData(updatedShiftData);
  };

  const renderDtModal = () => (
    <>
      <Modal
        modalType="doubleTime"
        dtModalData={dtModalData!}
        onCancelDT={() => setShowDoubletimeModal(false)}
        onSaveDT={doubleTime => {
          saveDoubleTimeHours(doubleTime!);
          setShowDoubletimeModal(false);
        }}
        getSigData={async data => {
          saveDoubleTimeSignature(data!);
        }}
      />
      ;
    </>
  );

  const handleWorkDescChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // setWorkDescText(e.target.value);
    setShiftData({
      ...shiftData,
      workDescription: e.target.value,
    });
    setShouldSaveDesc(true);
  };

  const handleSaveWorkDescription = async () => {
    const focus = await getFocus();
    const descText = shiftData?.workDescription;
    if (descText) {
      const res = await updateWorkDescription(focus, descText);
      if (res) {
        setShouldSaveDesc(false);
      }
    }
    const updatedShiftData = await getShiftData(focus);
    setShiftData(updatedShiftData);
  };

  const renderWorkDescription = () => {
    return (
      <textarea
        className="workreport-main-workdesc"
        name=""
        id=""
        cols={30}
        rows={10}
        onBlur={() => {
          shouldSaveDesc && handleSaveWorkDescription();
        }}
        onChange={e => handleWorkDescChange(e)}
        value={shiftData?.workDescription}
        placeholder="Enter Work Description Here..."
      ></textarea>
    );
  };

  const renderRemarks = () => {
    return (
      <textarea
        className="workreport-main-remarks"
        name="remarks"
        id="remarks"
        cols={30}
        rows={10}
        onBlur={async () => {
          const focus = await getFocus();
          const data = await updateRemarks(focus, shiftData?.remarks);
          setShiftData(data);
        }}
        onChange={e => {
          setShiftData({
            ...shiftData,
            remarks: e.target.value,
          });
        }}
        value={shiftData?.remarks}
        placeholder="Enter Remarks Here..."
      />
    );
  };

  const addMaterialDescRow = () => {
    const item: materialsDesc = {
      qty: 0,
      um: "",
      unitCost: 0,
      totalCost: 0,
      item: "",
      description: "",
      images: [],
      workzone: "",
    };
    setMaterialsDesc([item, ...materialsDesc!]);
  };

  const removeMaterialDescRow = () => {
    setMaterialsDesc(materialsDesc.slice(0, -1));
  };

  const totalCostRef = useRef([]);
  totalCostRef.current = [];
  let arr = [];
  const addToRef = (el: { value: never }) => {
    if (el && !totalCostRef.current.includes(el.value)) {
      totalCostRef.current.push(el.value);
    }
    arr = totalCostRef.current;
    let len = materialsDesc.length;
    for (let i = 0; i < len; i++) {
      materialsDesc[i].totalCost = parseFloat(arr[i]);
    }
  };

  const handleMaterialDescChange = (idx: number, e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const materialDescRows = [...materialsDesc];
    materialDescRows[idx] = {
      qty: name === "qty" ? parseFloat(value) : parseFloat(materialsDesc[idx].qty),
      um: name === "um" ? value : materialsDesc[idx].um,
      description: name === "description" ? value : materialsDesc[idx].description,
      unitCost: name === "unitCost" ? parseFloat(value) : parseFloat(materialsDesc[idx].unitCost),
      workzone: name === "workzone" ? value : materialsDesc[idx].um,
    };
    setMaterialsDesc(materialDescRows);
  };

  const handleSaveMaterialDesc = async () => {
    const focus = await getFocus();
    await updateMaterialDesc(focus, materialsDesc);
    const updatedShiftData = await getShiftData(focus);
    setShiftData(updatedShiftData);
    setMaterialsDesc([...updatedShiftData.materialsDesc]);
  };

  const handleJobImageView = (view: string, idx: number) => {
    if (view === "Add Image") {
      setImageAction(view);
      localStorage.setItem("materialImageAction", JSON.stringify({ view, idx }));
      navigate("/job-images");
    } else if (view === "View Images") {
      setImageAction(view);
      localStorage.setItem("materialImageAction", JSON.stringify({ view, idx }));
      navigate("/job-images");
    } else if (view === "Add Attachment") {
      setShowAttachmentModal(true);
    }
  };

  const handleRemoveMaterialDescRow = async (idx: number) => {
    const tempArr = [...materialsDesc];
    tempArr.splice(idx, 1);
    setMaterialsDesc(tempArr);
    const focus = await getFocus();
    await updateMaterialDesc(focus, tempArr);
    const updatedShiftData = await getShiftData(focus);
    setShiftData(updatedShiftData);
  };

  const renderAddAttachmentsModal = () => {
    return (
      <div>
        <PdfViewer
          pdfDoc={pdfAttachmnt}
          showModal={(s: boolean) => {
            setShowAttachmentModal(s);
          }}
        />
      </div>
    );
  };
  // console.log("FILE: ", pdfAttachmnt);
  const renderMaterialDescription = () => {
    return (
      <>
        <div className="workreport-main-materialdesc-add">
          <FontAwesomeIcon className="workreport-main-materialdesc-add-icon" icon={faPlus} onClick={addMaterialDescRow} />
        </div>
        <table
          className="workreport-main-materialdesc"
          onBlur={() => {
            handleSaveMaterialDesc();
          }}
        >
          <thead className="workreport-main-materialdesc-header">
            <tr className="workreport-main-materialdesc-header-rows">
              <th className="workreport-main-materialdesc-header-rows-item">QTY</th>
              <th className="workreport-main-materialdesc-header-rows-item">UM</th>
              <th className="workreport-main-materialdesc-header-rows-item">Material Description and/or Deliveries</th>
              <th className="workreport-main-materialdesc-header-rows-item">WORK ZONE</th>
              <th className="workreport-main-materialdesc-header-rows-item">IMAGES</th>
              <th className="workreport-main-materialdesc-header-rows-item">{""}</th>
            </tr>
          </thead>
          <tbody className="workreport-main-materialdesc-body">
            {materialsDesc.map((item: materialsDesc, index: number) => {
              return (
                <tr key={index} className="workreport-main-materialdesc-body-container">
                  <td className="workreport-main-materialdesc-body-container-item tdMatQty">
                    <input className="workreport-main-tasks-table-row-content-input matQtyInput" id="matQty" type="number" name="qty" value={materialsDesc[index]?.qty === 0 ? "" : parseFloat(materialsDesc[index]?.qty)} onChange={e => handleMaterialDescChange(index, e)} step="0.001" />
                  </td>
                  <td className="workreport-main-materialdesc-body-container-item tdMatUM">
                    <input
                      key={index}
                      className="workreport-main-tasks-table-row-content-input matUMInput"
                      type="text"
                      name="um"
                      value={materialsDesc[index]?.um}
                      onChange={e => {
                        handleMaterialDescChange(index, e);
                      }}
                    />
                  </td>
                  <td className="workreport-main-materialdesc-body-container-item tdMatDesc">
                    <textarea
                      key={index}
                      className="workreport-main-tasks-table-row-content-input matDescInput"
                      name="description"
                      value={materialsDesc[index]?.description}
                      onChange={e => {
                        handleMaterialDescChange(index, e);
                      }}
                    />
                  </td>
                  <td className="workreport-main-materialdesc-body-container-item tdUnitCost">
                    <select
                      className="workreport-main-tasks-table-row-content-input matQtyInput"
                      name="workzone"
                      id="workzones"
                      onChange={e => {
                        handleMaterialDescChange(index, e);
                      }}
                      value={materialsDesc[index]?.workzone}
                    >
                      <option value="none" selected>
                        Select Workzone
                      </option>
                      {shiftData?.workzones?.map((z: string, idx: number) => {
                        return (
                          <option key={idx} value={z}>
                            {z}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                  <td className="workreport-main-materialdesc-body-container-item tdUnitCost">
                    <select
                      className="workreport-main-tasks-table-row-content-input matQtyInput"
                      name="imageActions"
                      id="imageActions"
                      onChange={e => {
                        handleJobImageView(e.target.value, index);
                      }}
                      value={selectedImageAction ? selectedImageAction : ""}
                    >
                      <option value="none" selected>
                        Actions
                      </option>
                      {imageActions.map((a: string, idx: number) => {
                        return (
                          <option key={idx} value={a}>
                            {a}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                  <td className="workreport-main-materialdesc-body-container-item remove">
                    <div className="workreport-main-materialdesc-body-container-item remove-iconcontainer">
                      <FontAwesomeIcon
                        className="workreport-main-materialdesc-body-container-item remove-iconcontainer-icon"
                        icon={faMinusCircle}
                        onClick={() => {
                          handleRemoveMaterialDescRow(index);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  const renderIncidentReportQ = () => {
    return (
      <>
        <div className="workreport-main-incident">
          <div className="workreport-main-incident-checkbox-container">
            <label htmlFor="incident-yes">Yes</label>
            <input
              type="radio"
              id="incident-yes"
              onChange={async e => {
                setShiftData({
                  ...shiftData,
                  incidentReport: "yes",
                });
                const focus = await getFocus();
                await updateIncidentReportQ(focus, "yes");
                const data = await getShiftData(focus);
                setShiftData(data);
              }}
              checked={shiftData.incidentReport === "yes" ? true : false}
            />
          </div>
          <div className="workreport-main-incident-checkbox-container">
            <label htmlFor="incident-no">No</label>
            <input
              type="radio"
              id="incident-no"
              onChange={async e => {
                setShiftData({
                  ...shiftData,
                  incidentReport: "no",
                });
                const focus = await getFocus();
                await updateIncidentReportQ(focus, "no");
                const data = await getShiftData(focus);
                setShiftData(data);
              }}
              checked={shiftData?.incidentReport === "no" ? true : false}
            />
          </div>
        </div>
        <textarea
          className="workreport-main-incident-textinput"
          name="incidentReportText"
          id="incidentReportText"
          cols={120}
          rows={10}
          placeholder="Enter Incident Remarks Here"
          value={shiftData?.incidentReportText}
          onChange={async e => {
            setShiftData({
              ...shiftData,
              incidentReportText: e.target.value,
            });
          }}
          onBlur={async () => {
            const focus = await getFocus();
            const data = await updateIncidentReportText(focus, shiftData.incidentReportText);
            setShiftData(data);
          }}
        />
      </>
    );
  };

  const addTaskRow = () => {
    const item: tasksType = {
      title: "",
      status: "",
      progress: "",
      details: "",
    };
    setWorkTasks([item, ...workTasks!]);
  };
  const removeTaskRow = () => {
    setWorkTasks(workTasks.slice(0, -1));
  };

  const handleTaskChange = (idx: number, e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    const taskRows = [...workTasks];
    taskRows[idx] = {
      title: name === "title" ? value : workTasks[idx].title,
      status: name === "status" ? value : workTasks[idx].status,
      progress: name === "progress" ? value : workTasks[idx].progress,
      details: name === "details" ? value : workTasks[idx].details,
    };

    setWorkTasks(taskRows);
  };

  const handleSaveTasks = async () => {
    const focus = await getFocus();
    await updateTasks(focus, workTasks);
    const updatedShiftData = await getShiftData(focus);
    setShiftData(updatedShiftData);
    setWorkTasks([...updatedShiftData.tasks]);
  };

  const handleRemoveTaskRow = async (idx: number) => {
    const tempArr = [...workTasks];
    tempArr.splice(idx, 1);
    setWorkTasks(tempArr);
    const focus = await getFocus();
    await updateTasks(focus, tempArr);
    const updatedShiftData = await getShiftData(focus);
    setShiftData(updatedShiftData);
  };

  const renderTasks = () => {
    return (
      <div
        className="workreport-main-tasks"
        onBlur={() => {
          handleSaveTasks();
        }}
      >
        <div className="workreport-main-tasks-add">
          <FontAwesomeIcon className="workreport-main-tasks-add-icon" icon={faPlus} onClick={addTaskRow} />
        </div>
        <table className="workreport-main-tasks-table" id="tab_logic">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Status</th>
              <th scope="col">% Complete</th>
              <th scope="col">Description</th>
              <th scope="col">{""}</th>
            </tr>
          </thead>
          <tbody>
            {workTasks?.map((item: tasksType, index: number) => {
              return (
                <tr className="workreport-main-tasks-table-row" id="addr0" key={index}>
                  <td className="workreport-main-tasks-table-row-content">
                    <input
                      className="workreport-main-tasks-table-row-content-input"
                      type="text"
                      name="title"
                      value={workTasks[index].title}
                      onChange={e => {
                        handleTaskChange(index, e);
                      }}
                    />
                  </td>

                  <td className="workreport-main-tasks-table-row-content">
                    <select
                      className="workreport-main-tasks-table-row-content-input"
                      name="status"
                      id="status"
                      value={
                        workTasks[index].status
                        // progressNumber(workTasks[index].status)
                      }
                      defaultValue={taskProgressList[0].value}
                      onChange={e => handleTaskChange(index, e)}
                    >
                      <option value={"default"}>Select A Status</option>
                      {taskProgressList.map((item, index) => {
                        return (
                          <option key={index} value={item.value}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </td>

                  <td className="workreport-main-tasks-table-row-content wrNumberInputTd">
                    <input className="workreport-main-tasks-table-row-content-input wrNumberInput" id="wrNumberInput" type="number" name="progress" value={workTasks[index].progress} onChange={e => handleTaskChange(index, e)} />
                    <span>%</span>
                  </td>

                  <td className="workreport-main-tasks-table-row-content">
                    <textarea className="workreport-main-tasks-table-row-content-input" name="details" value={workTasks[index].details} onChange={e => handleTaskChange(index, e)} />
                  </td>
                  <td className="workreport-main-tasks-table-row-contentremove">
                    <div className="workreport-main-tasks-table-row-contentremove-removecontainer">
                      <FontAwesomeIcon
                        className="workreport-main-tasks-table-row-contentremove-removecontainer-icon"
                        icon={faMinusCircle}
                        onClick={() => {
                          handleRemoveTaskRow(index);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  let sigPad: SignatureCanvas | null;

  // const clearSignature = () => {
  //   sigPad?.clear();
  // };

  // const trimSignature = () => {
  //   const signatureData = sigPad?.getTrimmedCanvas().toDataURL("image/png");
  //   return signatureData;
  // };

  const updateConRepSig = async () => {
    const updatedShiftData = await updateContractorRepSignature(await getFocus(), shiftData?.contractorRepSignature);
    setShiftData(updatedShiftData);
  };

  let sigPad1: SignatureCanvas | null;
  let sigPad2: SignatureCanvas | null;
  let sigPad3: SignatureCanvas | null;

  const trimContractorRepSig = async () => {
    const signatureData = sigPad1?.getTrimmedCanvas().toDataURL("image/png");
    const updatedShiftData = await updateContractorRepSignature(await getFocus(), signatureData!);

    setShiftData(updatedShiftData);

    // setShiftData({
    //   ...shiftData,
    //   contractorRepSignature: signatureData,
    // });
  };

  const trimAgencyRepSig = async () => {
    const signatureData = sigPad2?.getTrimmedCanvas().toDataURL("image/png");

    const updatedShiftData = await updateAgencyRepSignature(await getFocus(), signatureData!);

    setShiftData(updatedShiftData);
    // setShiftData({
    //   ...shiftData,
    //   agencyRepSignature: signatureData,
    // });
  };

  const trimPMSig = async () => {
    const signatureData = sigPad3?.getTrimmedCanvas().toDataURL("image/png");

    const updatedShiftData = await updatePMSignature(await getFocus(), signatureData!);
    setShiftData(updatedShiftData);

    // setShiftData({
    //   ...shiftData,
    //   foremanSignature: signatureData,
    // });
  };

  const renderApprovals = () => {
    return (
      <div className="workreport-main-approvals">
        <div className="workreport-main-approvals-namescontainer">
          <div className="workreport-main-approvals-namescontainer-sig">
            <label className="workreport-main-approvals-namescontainer-sig-label" htmlFor="contractorRepSig">
              Contractor Rep Signature:
            </label>
            {!shiftData?.contractorRepSignature ? (
              <SignatureCanvas
                ref={ref => (sigPad1 = ref)}
                canvasProps={{
                  style: {
                    backgroundColor: "#d6dce1",
                    height: "8rem",
                    width: "50rem",
                  },
                }}
                onEnd={() => {
                  trimContractorRepSig();
                }}
              />
            ) : (
              <img
                src={shiftData?.contractorRepSignature}
                alt="contractorRepSignature"
                style={{
                  backgroundColor: "#d6dce1",
                  height: "8rem",
                  width: "50rem",
                }}
              />
            )}
          </div>
          <div className="workreport-main-approvals-namescontainer-name">
            <label className="workreport-main-approvals-namescontainer-name-label" htmlFor="contractorRepName">
              Name:
            </label>
            <input
              className="workreport-main-approvals-namescontainer-name-input"
              type="text"
              placeholder="Enter Contractor Rep Name Here..."
              name="contractorRepName"
              value={shiftData?.contractorRep}
              onChange={async e => {
                setShiftData({
                  ...shiftData,
                  contractorRep: e.target.value,
                });
                const updatedShiftData = await updateContractorRepName(await getFocus(), e.target.value);
              }}
            />
          </div>
        </div>

        <div className="workreport-main-approvals-namescontainer">
          <div className="workreport-main-approvals-namescontainer-sig">
            <label className="workreport-main-approvals-namescontainer-sig-label" htmlFor="contractorRepSig">
              Agency Rep Signature:
            </label>
            {!shiftData?.agencyRepSignature ? (
              <SignatureCanvas
                ref={ref => (sigPad2 = ref)}
                canvasProps={{
                  style: {
                    backgroundColor: "#d6dce1",
                    height: "8rem",
                    width: "50rem",
                  },
                }}
                onEnd={() => {
                  trimAgencyRepSig();
                }}
              />
            ) : (
              <img
                src={shiftData?.agencyRepSignature}
                alt="contractorRepSignature"
                style={{
                  backgroundColor: "#d6dce1",
                  height: "8rem",
                  width: "50rem",
                }}
              />
            )}
          </div>
          <div className="workreport-main-approvals-namescontainer-name">
            <label className="workreport-main-approvals-namescontainer-name-label" htmlFor="contractorRepName">
              Name:
            </label>
            <input
              className="workreport-main-approvals-namescontainer-name-input"
              type="text"
              placeholder="Enter Agency Rep Name Here..."
              value={shiftData?.agencyRep}
              onChange={async e => {
                setShiftData({
                  ...shiftData,
                  agencyRep: e.target.value,
                });
                const updatedShiftData = await updateAgencyRepName(await getFocus(), e.target.value);
              }}
            />
          </div>
        </div>

        <div className="workreport-main-approvals-namescontainer">
          <div className="workreport-main-approvals-namescontainer-sig">
            <label className="workreport-main-approvals-namescontainer-sig-label" htmlFor="contractorRepSig">
              Foreman/Project Manager Signature:
            </label>
            {!shiftData?.foremanSignature ? (
              <SignatureCanvas
                ref={ref => (sigPad3 = ref)}
                canvasProps={{
                  style: {
                    backgroundColor: "#d6dce1",
                    height: "8rem",
                    width: "50rem",
                  },
                }}
                onEnd={() => {
                  trimPMSig();
                }}
              />
            ) : (
              <img
                src={shiftData?.foremanSignature}
                alt="contractorRepSignature"
                style={{
                  backgroundColor: "#d6dce1",
                  height: "8rem",
                  width: "50rem",
                }}
              />
            )}
          </div>
          <div className="workreport-main-approvals-namescontainer-name">
            <label className="workreport-main-approvals-namescontainer-name-label" htmlFor="contractorRepName">
              Name:
            </label>
            <input
              className="workreport-main-approvals-namescontainer-name-input"
              type="text"
              placeholder="Enter Foreman/PM Name Here..."
              value={shiftData?.siteEmployees.foreman.firstName + " " + shiftData?.siteEmployees.foreman.lastName}
              onChange={e => {
                setShiftData({
                  ...shiftData,
                  foreman: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const handleSaveChangeOrderTitle = async () => {
    const focus = await getFocus();
    const data = await updateChangeOrderTitle(focus, shiftData?.titleOfChangeOrder);
    setShiftData(data);
  };

  const renderChangeOrderTitle = () => {
    return (
      <div className="workreport-main-changeordertitle">
        <label className="workreport-main-changeordertitle-label" htmlFor="changeOrderTitle">
          Title Of Change Order/T&M/Extra Work Performed:
        </label>
        <input
          className="workreport-main-changeordertitle-input"
          type="text"
          placeholder="Enter Change Order Title Here..."
          onChange={e => {
            setShiftData({
              ...shiftData,
              titleOfChangeOrder: e.target.value,
            });
          }}
          value={shiftData?.titleOfChangeOrder || ""}
          onBlur={() => {
            handleSaveChangeOrderTitle();
          }}
        />
      </div>
    );
  };

  if (!getUser()) {
    return <Navigate to="/user-login" replace={true} />;
  }

  if (getUser() && getUser().role === "ADMIN") {
    return <Navigate to="/jobs" replace={true} />;
  }

  if (!shiftData) {
    return <div className="workreport-nodata">No Sign-In Sign-Out Sheet Created</div>;
  }

  return (
    <div className="workreport">
      <Header site={shiftData} workShift={(shift: string) => setSelectedShift(shift)} />
      <div className="workreport-main">
        <div className="workreport-main-section-divider">
          <FontAwesomeIcon className="workreport-main-section-divider-icon" icon={showChangeOrderTitle ? faChevronUp : faChevronDown} onClick={() => setShowChangeOrderTitle(!showChangeOrderTitle)} />
          <h4 className="workreport-main-section-divider-title">Title Of Change Order (if any)</h4>
        </div>
        {showChangeOrderTitle && renderChangeOrderTitle()}

        <div className="workreport-main-section-divider">
          <FontAwesomeIcon className="workreport-main-section-divider-icon" icon={showEmployees ? faChevronUp : faChevronDown} onClick={() => setShowEmployees(!showEmployees)} />
          <h4 className="workreport-main-section-divider-title">Employees</h4>
        </div>
        {showEmployees && renderEmployees()}

        <div className="workreport-main-section-divider">
          <FontAwesomeIcon className="workreport-main-section-divider-icon" icon={showWorkDesc ? faChevronUp : faChevronDown} onClick={() => setShowWorkDesc(!showWorkDesc)} />
          <h4 className="workreport-main-section-divider-title">Work Description</h4>
        </div>
        {showWorkDesc && renderWorkDescription()}

        <div className="workreport-main-section-divider">
          <FontAwesomeIcon className="workreport-main-section-divider-icon" icon={showMaterialDesc ? faChevronUp : faChevronDown} onClick={() => setShowMaterialDesc(!showMaterialDesc)} />
          <h4 className="workreport-main-section-divider-title">Material Description/Deliveries</h4>
        </div>
        {showMaterialDesc && renderMaterialDescription()}

        <div className="workreport-main-section-divider">
          <FontAwesomeIcon className="workreport-main-section-divider-icon" icon={showIncidentRep ? faChevronUp : faChevronDown} onClick={() => setShowIncidentRep(!showIncidentRep)} />
          <h4 className="workreport-main-section-divider-title">Incident Report?</h4>
        </div>
        {showIncidentRep && renderIncidentReportQ()}

        <div className="workreport-main-section-divider">
          <FontAwesomeIcon className="workreport-main-section-divider-icon" icon={showTasks ? faChevronUp : faChevronDown} onClick={() => setShowTasks(!showTasks)} />
          <h4 className="workreport-main-section-divider-title">Tasks</h4>
        </div>
        {showTasks && renderTasks()}

        <div className="workreport-main-section-divider">
          <FontAwesomeIcon className="workreport-main-section-divider-icon" icon={showRemarks ? faChevronUp : faChevronDown} onClick={() => setShowRemarks(!showRemarks)} />
          <h4 className="workreport-main-section-divider-title">Remarks</h4>
        </div>
        {showRemarks && renderRemarks()}

        <div className="workreport-main-section-divider">
          <FontAwesomeIcon className="workreport-main-section-divider-icon" icon={showApprovals ? faChevronUp : faChevronDown} onClick={() => setShowApprovals(!showApprovals)} />
          <h4 className="workreport-main-section-divider-title">Approvals</h4>
        </div>
        {showApprovals && renderApprovals()}
      </div>

      <Button1
        btnName="closeMenuBtn"
        btnClassName="workreport-submitBtn"
        btnType="button"
        btnValue="Submit For Review"
        onclick={() => {
          handleAddSigninSignoutWr();
        }}
        shouldDisable={!navigator.onLine}
      />
      {showDoubletimeModal && renderDtModal()}
      <>{showNotesModal && renderNotesModal()}</>
      <>{showAttachmentsModal && renderAddAttachmentsModal()}</>
    </div>
  );
};

export default WorkReport;
