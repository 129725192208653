import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { createEmployee } from "../../../graphql/queries";
import { confirmUser, userRegistration } from "../../../api/auth";
import Button1 from "../../../common/button1/Button1";

import "./RegisterUser.scss";

type registrationType = {
  admin: string;
  field: string;
};
const employeeLevels = [{ no: 1 }, { no: 2 }, { no: 3 }];

const registrationTypes = [
  { name: "Admin", value: "ADMIN" },
  { name: "Field", value: "FIELD" },
];

const RegisterUser: React.FC = () => {
  const [credentials, setCredentials] = useState<{ username: string; password: string } | any | null>(null);
  const [registrationType, setRegistrationType] = useState<registrationType | string | null>(null);
  const [registration, setRegistration] = useState<any | null>(null);
  const [shouldRegisterAuth, setShouldRegisterAuth] = useState<boolean>(false);
  const [hasRegistered, setHasRegistered] = useState<boolean>(false);

  const [addEmployee, { data: addEmployeeData, loading: addEmployeeLoading, error: addEmployeeError }] = useMutation(createEmployee);

  useEffect(() => {
    setRegistrationType("ADMIN");
  }, []);

  const handleSubmitAdminRegistration = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const updatedRegistration = {
      ...registration,
      role: "ADMIN",
    };
    // console.log('SUBMITTING REGISTRATION: ', updatedRegistration)
    const data = await addEmployee({ variables: { body: updatedRegistration } });
    // console.log('ADD EMPLOYEE DATA: ', data);
    setRegistration(null);
    setShouldRegisterAuth(true);
  };

  const handleSubmitFieldRegistration = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const updatedRegistration = {
      ...registration,
      role: "FIELD",
    };
    const data = await addEmployee({ variables: { body: updatedRegistration } });

    setRegistration(null);
    setShouldRegisterAuth(true);
  };

  const handleAuthSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      const data: { ok: boolean; type: string } | any = await userRegistration(credentials?.userName, credentials?.password);
      if (data?.status === 201) {
        // alert(data?.data?.msg);
        const userConfirmation = await confirmUser(credentials?.userName);
        setHasRegistered(true);
        setCredentials(null);
        // return <Navigate to="/user-login" replace={true} />
      } else {
        alert(data?.response?.data?.msg);
        setCredentials(null);
      }
    } catch (err) {
      console.log("REGISTRATION ERROR ", err);
      alert("Registration Error");
    }
  };

  const renderRegistrationTypes = () => {
    return (
      <div className="registeruser-registerationtype">
        <label className="registeruser-registerationtype-label">Select Employee Registration Type</label>
        <select
          className="registeruser-registerationtype-menu"
          name="registrationSelect"
          id="registrationSelect"
          onChange={e => {
            setRegistrationType(e.target.value);
          }}
        >
          {registrationTypes.map((t: { name: string; value: string }, idx: number) => {
            return (
              <option className="registeruser-registerationtype-menu-item" key={idx} value={t.value}>
                {t.name}
              </option>
            );
          })}
        </select>
        {/* <button type='submit' className='registeruser-registerationtype-submitbtn'>Select Type</button> */}
      </div>
    );
  };

  const renderFieldRegistration = () => {
    return (
      <>
        <div className="registeruser-fieldform">
          <div className="registeruser-fieldform-row1">
            <div className="registeruser-fieldform-row1-firstName">
              <label htmlFor="firstName">First Name</label>
              <input
                className="registeruser-fieldform-row1-firstName-input"
                type="text"
                name="firstName"
                id="firstName"
                value={registration?.firstName || ""}
                onChange={e => {
                  setRegistration({
                    ...registration,
                    [`${e.target.name}`]: e.target.value,
                  });
                }}
              />
            </div>

            <div className="registeruser-fieldform-row1-lastName">
              <label htmlFor="lastName">Last Name</label>
              <input
                className="registeruser-fieldform-row1-lastName-input"
                type="text"
                name="lastName"
                id="lastName"
                value={registration?.lastName || ""}
                onChange={e => {
                  setRegistration({
                    ...registration,
                    [`${e.target.name}`]: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="registeruser-fieldform-row2">
            <div className="registeruser-fieldform-row2-middleInitial">
              <label htmlFor="middleInitial">Middle Initials</label>
              <input
                className="registeruser-fieldform-row2-middleInitial-input"
                type="text"
                name="middleInitial"
                id="middleInitial"
                value={registration?.middleInitial || ""}
                onChange={e => {
                  setRegistration({
                    ...registration,
                    [`${e.target.name}`]: e.target.value,
                  });
                }}
              />
            </div>

            <div className="registeruser-fieldform-row2-class">
              <label htmlFor="class">Class</label>
              <input
                className="registeruser-fieldform-row2-class-input"
                type="text"
                name="class"
                id="class"
                value={registration?.class || ""}
                onChange={e => {
                  setRegistration({
                    ...registration,
                    [`${e.target.name}`]: e.target.value,
                  });
                }}
              />
            </div>

            <div className="registeruser-fieldform-row2-last4SSN">
              <label htmlFor="last4SSN">SSN (Last 4)</label>
              <input
                className="registeruser-fieldform-row2-last4SSN-input"
                type="text"
                name="last4SSN"
                id="last4SSN"
                value={registration?.last4SSN || ""}
                onChange={e => {
                  setRegistration({
                    ...registration,
                    [`${e.target.name}`]: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="registeruser-fieldform-row3">
            <div className="registeruser-fieldform-row3-email">
              <label htmlFor="email">Email</label>
              <input
                className="registeruser-fieldform-row2-email-input"
                type="email"
                name="email"
                id="email"
                value={registration?.email || ""}
                onChange={e => {
                  setRegistration({
                    ...registration,
                    [`${e.target.name}`]: e.target.value,
                  });
                }}
              />
            </div>
            <div className="registeruser-fieldform-row2-phone">
              <label htmlFor="phone">Phone</label>
              <input
                className="registeruser-fieldform-row2-phone-input"
                type="phone"
                name="phone"
                id="phone"
                value={registration?.phone || ""}
                onChange={e => {
                  setRegistration({
                    ...registration,
                    [`${e.target.name}`]: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="registeruser-fieldform-btngroup">
            <button
              className="registeruser-fieldform-btngroup-savebtn"
              type="button"
              onClick={e => {
                handleSubmitFieldRegistration(e);
              }}
            >
              Save
            </button>

            <button
              className="registeruser-fieldform-btngroup-savebtn"
              type="button"
              onClick={() => {
                setRegistration(null);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        {renderAuthRegistration()}
      </>
    );
  };

  const renderAdminRegistration = () => {
    return (
      <>
        <div className="registeruser-fieldform">
          <div className="registeruser-fieldform-row1">
            <div className="registeruser-fieldform-row1-firstName">
              <label htmlFor="firstName">First Name</label>
              <input
                className="registeruser-fieldform-row1-firstName-input"
                type="text"
                name="firstName"
                id="firstName"
                value={registration?.firstName || ""}
                onChange={e => {
                  setRegistration({
                    ...registration,
                    [`${e.target.name}`]: e.target.value,
                  });
                }}
              />
            </div>

            <div className="registeruser-fieldform-row1-lastName">
              <label htmlFor="lastName">Last Name</label>
              <input
                className="registeruser-fieldform-row1-lastName-input"
                type="text"
                name="lastName"
                id="lastName"
                value={registration?.lastName || ""}
                onChange={e => {
                  setRegistration({
                    ...registration,
                    [`${e.target.name}`]: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="registeruser-fieldform-row2">
            <div className="registeruser-fieldform-row2-middleInitial">
              <label htmlFor="middleInitial">Middle Initials</label>
              <input
                className="registeruser-fieldform-row2-middleInitial-input"
                type="text"
                name="middleInitial"
                id="middleInitial"
                value={registration?.middleInitial || ""}
                onChange={e => {
                  setRegistration({
                    ...registration,
                    [`${e.target.name}`]: e.target.value,
                  });
                }}
              />
            </div>

            <div className="registeruser-fieldform-row2-email">
              <label htmlFor="email">Email</label>
              <input
                className="registeruser-fieldform-row2-email-input"
                type="text"
                name="email"
                id="email"
                value={registration?.email || ""}
                onChange={e => {
                  setRegistration({
                    ...registration,
                    [`${e.target.name}`]: e.target.value,
                  });
                }}
              />
            </div>

            <div className="registeruser-fieldform-row2-level">
              <label htmlFor="level">Level</label>
              <select
                className="registeruser-fieldform-row2-level-menu"
                name="level"
                id="level"
                value={registration?.level}
                onChange={e => {
                  setRegistration({
                    ...registration,
                    [`${e.target.name}`]: parseInt(e.target.value, 10),
                  });
                }}
              >
                <option value="" className="registeruser-fieldform-row2-level-menu-item">
                  Select Level
                </option>
                {employeeLevels.map((level: { no: number }) => {
                  return (
                    <option key={level.no} value={level.no} className="registeruser-fieldform-row2-level-menu-item">
                      {level.no}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="registeruser-fieldform-row3">
            <div className="registeruser-fieldform-row3-phone">
              <label htmlFor="phone">Phone</label>
              <input
                className="registeruser-fieldform-row3-phone-input"
                type="phone"
                name="phone"
                id="phone"
                value={registration?.phone || ""}
                onChange={e => {
                  setRegistration({
                    ...registration,
                    [`${e.target.name}`]: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="registeruser-fieldform-btngroup">
            <button
              className="registeruser-fieldform-btngroup-savebtn"
              type="submit"
              onClick={e => {
                handleSubmitAdminRegistration(e);
              }}
            >
              Save
            </button>

            <button className="registeruser-fieldform-btngroup-savebtn" type="button">
              Cancel
            </button>
          </div>
        </div>
        {renderAuthRegistration()}
      </>
    );
  };

  const renderAuthRegistration = () => {
    return (
      <div className="registeruser-authform">
        <div className="registeruser-authform-row1">
          <div className="registeruser-authform-row1-userName">
            <label htmlFor="userName">Email</label>
            <input
              className="registeruser-authform-row1-userName-input"
              type="email"
              name="userName"
              id="userName"
              // disabled={!shouldRegisterAuth}
              value={credentials?.userName || ""}
              onChange={e => {
                setCredentials({
                  ...credentials,
                  [`${e.target.name}`]: e.target.value,
                });
              }}
            />
          </div>

          <div className="registeruser-authform-row1-password">
            <label htmlFor="password">Password</label>
            <input
              className="registeruser-authform-row1-password-input"
              type="password"
              name="password"
              id="password"
              // disabled={!shouldRegisterAuth}
              value={credentials?.password || ""}
              onChange={e => {
                setCredentials({
                  ...credentials,
                  [`${e.target.name}`]: e.target.value,
                });
              }}
            />
          </div>
        </div>

        <div className="registeruser-authform-btngroup">
          <button
            className="registeruser-authform-btngroup-savebtn"
            type="submit"
            // disabled={!shouldRegisterAuth}
            onClick={e => {
              handleAuthSubmit(e);
            }}
          >
            Save
          </button>

          <button
            className="registeruser-fieldform-btngroup-savebtn"
            type="button"
            // disabled={!shouldRegisterAuth}
            onClick={() => {
              setRegistration(null);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  const renderAuthRedirectConfirmation = () => {
    return (
      <div className="registeruser-confirmation">
        <h1 className="registeruser-confirmation-header1">Workforce Daily Report</h1>
        <h2 className="registeruser-confirmation-header2">Registration Successful!</h2>
        <div className="registeruser-confirmation-message">
          <p className="registeruser-confirmation-message-text">Please check for email for the confirmation link.</p>
          <p className="registeruser-confirmation-message-text">Click on the link.</p>
          <p className="registeruser-confirmation-message-text">When you open the link you will enter the 6 digit code that was sent in the confirmation email.</p>
          <p className="registeruser-confirmation-message-text">Once you have successfully entered the 6 digit code you can log in.</p>
        </div>
      </div>
    );
  };

  return (
    <div className="registeruser">
      {!hasRegistered ? (
        <>
          <div className="registeruser-header">
            <h1 className="registeruser-header-title">Workforce Daily Report</h1>
            <h2 className="registeruser-header-title2">User Credentials Registration</h2>
          </div>
          <>
            {renderRegistrationTypes()}
            {registrationType === "FIELD" && renderFieldRegistration()}
            {registrationType === "ADMIN" && renderAdminRegistration()}
            {renderAuthRedirectConfirmation()}
          </>
        </>
      ) : (
        renderAuthRedirectConfirmation()
      )}
    </div>
  );
};

export default RegisterUser;
